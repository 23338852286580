/* eslint-disable */
import React, { Component } from "react";
import billingFormStyling from './billingFormStyling'
import PaymentInformation from './paymentInformation'
import { ElementsConsumer } from '@stripe/react-stripe-js';
import StripeWrapper from '../StripeWrapper/index';
import stateSelections from '../helpers/stateSelections/stateSelections';

class BillingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            firstName: '',
            lastName: '',
            creditCardName: '',

            submitClicked: false,
        };
    }

    creditCardNameHandle = (e) => {
        this.setState({
            creditCardName: e.target.value
        })
    }

    stripeHandle = async (elements, stripe) => {
        const { submitClicked, creditCardName } = this.state
        const { signupStepTwoHandle, firstName, lastName, address1, address2, city, stateName, zipcode, email, serviceInformationFormHandle,
            billingStreetAddress1, billingStreetAddress2, billingCityName, billingStateName, billingZipCode, sameAsBillingAddress } = this.props

        if (submitClicked) {
            const result = await stripe.confirmSetup({
                elements,
                redirect: 'if_required',
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            address: {
                                line1: !sameAsBillingAddress
                                    ? `${billingStreetAddress1}`
                                    : `${address1}`,
                                line2: !sameAsBillingAddress
                                    ? `${billingStreetAddress2}`
                                    : `${address2}`,
                                city: !sameAsBillingAddress ? billingCityName : city,
                                state: !sameAsBillingAddress ? billingStateName : stateName,
                                postal_code: !sameAsBillingAddress ? billingZipCode : zipcode,
                                country: 'US',
                            },
                            email: email,
                            name: creditCardName.length > 0 ? creditCardName : firstName + ' ' + lastName,
                        },
                    },
                },
            });

            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                this.setState({ submitClicked: false, isLoading: false })
                console.log(result.error);
                // alert(result.error.message);
                let errorMsg = `A processing error occurred. Please try again or refresh the page`
                alert(errorMsg);
            } else {
                // console.log('success for stripe.confirmSetup:', result)
                // step two goes here
                // serviceInformationFormHandle() 
                signupStepTwoHandle(result)
            }
        }
    }

    submitHandle = () => {
        const { address1, city, stateName, zipcode, sameAsBillingAddress } = this.props
        const { isLoading } = this.state
        // this will enable stripeHandle to create setup intent
        if (!isLoading) {
            if (sameAsBillingAddress) {
                this.setState({ submitClicked: true, isLoading: true })
            } else {
                if (address1 && city && stateName && zipcode) {
                    this.setState({ submitClicked: true })
                }
            }
        }

    }

    render() {
        const { creditCardName, isLoading } = this.state
        const { billingIntent, address1, address2, city, address1BillingHandle, address2BillingHandle,
            cityBillingHandle, stateNameBillingHandle,
            zipCodeBillingHandle,
            sameAsBillingAddressHandle,
            stateName, zipcode,
            billingStreetAddress1, billingStreetAddress2, billingCityName,
            firstName, lastName, sameAsBillingAddress,
            billingZipCode, billingStateName,
        } = this.props

        return (
            <div style={billingFormStyling.formFont}>
                {/* <div className="text-left" style={billingFormStyling.titleFont}>
                    <span className='fas fa-check-circle'>{` `}</span>
                    {`  Connect Square Credit Card Swiper`}
                </div> */}

                <div style={billingFormStyling.paddingBetweenInputs}></div>

                <div className="row show_if_mobile_screen hide_if_not_mobile_screen text-left">
                    <div className="col-12" style={billingFormStyling.sectionFontTitle}>
                        <div style={{ fontWeight: 'light bold', fontSize: '1.6vw' }}>
                            Billing Address
                        </div>
                    </div>
                    <div style={{ paddingTop: '5%' }}></div>
                </div>

                <div className="row" >

                    <div className="col-2 text-left text-left hide_if_mobile_screen" style={billingFormStyling.sectionFontTitle}>
                        Billing Address
                    </div>

                    <div className="col-10">
                        <div className="row text-left">
                            <div className="col-12">
                                <div className="">
                                    <input
                                        type="checkbox"
                                        aria-label="Radio button for following text input"
                                        value={sameAsBillingAddress}
                                        onClick={sameAsBillingAddressHandle}
                                        name="glassServiceSelected"
                                        defaultChecked={sameAsBillingAddress ? true : false}
                                    >
                                    </input>
                                    <span style={{
                                        color: '#848484',
                                        fontSize: '1.4vw'
                                    }}>
                                        {` Same as Home address`}
                                    </span>
                                </div>
                                <div style={billingFormStyling.paddingBetweenInputs}></div>
                            </div>


                            <div className="col-12">
                                <div style={billingFormStyling.inputTitleStyle}>
                                    Address line 1
                                    <span style={billingFormStyling.asteriskColor}>{` *`}</span>
                                </div>
                                {/* will be replaced with props or squre CC swiper */}
                                <input
                                    disabled={sameAsBillingAddress ? true : false}
                                    style={billingFormStyling.inputStyling}
                                    type="text"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder={sameAsBillingAddress ? address1 : billingStreetAddress1}
                                    value={billingStreetAddress1}
                                    onChange={address1BillingHandle}
                                ></input>
                                <div style={billingFormStyling.paddingBetweenInputs}></div>
                            </div>


                            <div className="col-12">
                                <div style={billingFormStyling.inputTitleStyle}>
                                    Address line 2
                                </div>
                                {/* will be replaced with props or squre CC swiper */}
                                <input
                                    disabled={sameAsBillingAddress ? true : false}
                                    style={billingFormStyling.inputStyling}
                                    type="text"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder={sameAsBillingAddress ? address2 : billingStreetAddress2}
                                    value={billingStreetAddress2}
                                    onChange={address2BillingHandle}
                                ></input>
                                <div style={billingFormStyling.paddingBetweenInputs}></div>
                            </div>


                            <div className="col-6">
                                <div style={billingFormStyling.inputTitleStyle}>
                                    City  <span style={billingFormStyling.asteriskColor}>*</span>
                                </div>
                                {/* will be replaced with props or squre CC swiper */}
                                <input
                                    disabled={sameAsBillingAddress ? true : false}
                                    style={billingFormStyling.inputStyling}
                                    type="text"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder={sameAsBillingAddress ? city : billingCityName}
                                    value={billingCityName}
                                    onChange={cityBillingHandle}
                                ></input>
                            </div>

                            <div className="col-6">
                                <div style={billingFormStyling.inputTitleStyle}>
                                    State <span style={billingFormStyling.asteriskColor}>*</span>
                                </div>
                                <select
                                    disabled={sameAsBillingAddress ? true : false}
                                    id='formGroupExampleInput'
                                    className="form-control"
                                    onChange={stateNameBillingHandle}
                                    value={sameAsBillingAddress ? stateName : billingStateName}
                                    style={{
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        borderRadius: '0px',
                                        width: '40%',
                                        color: '#848484'
                                    }}
                                >
                                    <option value="">
                                        {sameAsBillingAddress ? stateName : billingStateName}
                                    </option>
                                    {stateSelections
                                        ? stateSelections.map((state, i) => {
                                            return (
                                                <option key={i} value={state.value} >
                                                    {state.value}
                                                </option>
                                            );
                                        })
                                        : ''}
                                </select>
                            </div>

                            <div style={billingFormStyling.paddingBetweenInputs}></div>

                            <div className="col-12">
                                <div style={billingFormStyling.inputTitleStyle}>
                                    Zip code <span style={billingFormStyling.asteriskColor}>*</span>
                                </div>
                                <input
                                    disabled={sameAsBillingAddress ? true : false}
                                    style={billingFormStyling.inputStyling}
                                    type="text"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder={sameAsBillingAddress ? zipcode : billingZipCode}
                                    value={billingZipCode}
                                    onChange={zipCodeBillingHandle}
                                ></input>
                                <div style={billingFormStyling.paddingBetweenInputs}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={billingFormStyling.paddingBetweenInputs}></div>
                <div className="hide_if_mobile_screen" style={billingFormStyling.paddingBetweenInputs}></div>


                <div className="row show_if_mobile_screen hide_if_not_mobile_screen text-left">
                    <div className="col-12" style={billingFormStyling.sectionFontTitle}>
                        <div style={{ fontWeight: 'light bold', fontSize: '1.6vw' }}>
                            Payment information
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 text-left hide_if_mobile_screen" style={billingFormStyling.sectionFontTitle}>
                        Payment information
                    </div>
                    <div className="col-10 text-left">
                        <StripeWrapper clientSecret={billingIntent?.clientSecret}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => (
                                    <PaymentInformation
                                        stripe={stripe}
                                        elements={elements}
                                        firstName={firstName}
                                        lastName={lastName}
                                        creditCardName={creditCardName}
                                        creditCardNameHandle={this.creditCardNameHandle}
                                        stripeHandle={this.stripeHandle}
                                    />
                                )}
                            </ElementsConsumer>
                        </StripeWrapper>
                    </div>
                </div>

                <div style={billingFormStyling.spacingBetweenDivs}></div>
                <div className="row">
                    <div className="col-6 text-left" onClick={this.props.previousPage}>
                        <div className="row">
                            <div className="col-sm text-center" style={billingFormStyling.buttonForNextPage}>
                                Back
                            </div>
                            <div className="col-sm">
                            </div>
                            <div className="col-sm">
                            </div>
                        </div>
                    </div>
                    <div className="col-6 text-right"
                    // onClick={this.nextPageHandle}
                    >
                        <div className="row">
                            <div className="col-sm">
                            </div>
                            <div className="col-sm">
                            </div>
                            <div disabled={isLoading} className="col-sm text-center" style={billingFormStyling.buttonForNextPage} onClick={this.submitHandle}>
                                {isLoading ? 'loading...' : 'Submit'}
                                {/* {`Submit`} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BillingForm
