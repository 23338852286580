import React, { useState, useEffect } from "react";
import QRCodeGenerator from './QRCodeGenerator'

function QRPassCode({ qrGeneratorHandle }) {
    const [passcode, setPasscode] = useState('')
    const [passcodeConfirmed, setPasscodeConfirmed] = useState(false)

    const handleChange = (e) => {
        setPasscode(e.target.value)
    }

    useEffect(() => {
        if (passcode === 'murphutah') {
            setPasscodeConfirmed(true)
        } else {
            setPasscodeConfirmed(false)
        }
    }, [passcode, passcodeConfirmed])

    return (
        <div>
            {passcodeConfirmed
                ?
                <div className="container center-content">
                    <QRCodeGenerator qrGeneratorHandle={qrGeneratorHandle} />
                </div>
                :
                <div>
                    <input
                        placeholder="Enter passcode"
                        onChange={handleChange}
                        value={passcode}
                        name="passcode"
                        type="text"
                        data-testid="passcodeId"
                        className="input-profile-style form-control"
                    />
                    <div className="center-content" style={{ marginTop: '20px' }}>
                        <button className="generate-qr-btn" onClick={qrGeneratorHandle}>Back</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default QRPassCode