/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// import AuthStateApp from './AuthStateApp'
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// redux
import { Provider } from "react-redux";
import store from './components/redux/store/store'

import { Auth } from 'aws-amplify';

import awsconfig from './aws-exports';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE),
  environment: process.env.REACT_APP_STAGE
});

console.log('Sentry initialized', process.env.REACT_APP_STAGE);

// Amplify.configure(awsconfig);
// >>New - Configuring Auth Module

Auth.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
