const customerSignupFormStyling = {
    paddingBetweenInputs: {
        padding: '3%',
    },
    paddingBetweenTitleAndInputs: {
        padding: '.6%',
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    asteriskColor: {
        color: 'red'
    },
    formFont: {
        color: '#848484',
        fontSize: '1.3vw'
    },
    inputStyling: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px'
    },
    dropDownStyling: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px',
        width: '40%',
        color: '#848484',
        fontSize: '1.3vw'
    },
    dropDownStateNameStyling: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px',
        width: '40%',
        color: '#848484',
        fontSize: '1.3vw'
    },
    calendarSelection: {
        width: '40%',
    },
    sectionFontTitle: {
        color: 'black',
        fontWeight: 'light bold',
        fontSize: '1.5vw'
    },
    inputTitleStyle: {
        color: '#848484',
        fontWeight: 'bold',
        fontSize: '1.4vw'
    },
    inputTitleStyle2: {
        color: '#848484',
        fontWeight: 'bold',
        fontSize: '1.3vw'
    },
    buttonForNextPage: {
        background: '#2A9B8D',
        borderRadius: '20px',
        padding: '1.5%',
        color: 'white'
    },
    errorMsgColor: {
        color: 'red'
    },
    scrollbarContainer: {
        position: 'relative',
        // height: '420px',
        height: '45vw',
        overflow: 'scroll',
        // marginBottom: '100px'
    },
    scrollbarMargin: {
        marginBottom: '100px'
    },
};

export default customerSignupFormStyling