/* eslint-disable */
import React, { Component } from "react";
import NewPassword from './newPassword'

class ConfirmCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            enterNewPassword: false
        };
    }

    confirmationCodeHandle = (event) => {
        this.setState({
            code: event.target.value
        })
    }

    confirmCodeHandleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            enterNewPassword: !this.state.enterNewPassword,
        })
    }

    render() {
        const { enterNewPassword, code } = this.state
        const { email } = this.props
        return (
            <div>
                {!enterNewPassword
                    ? <form onSubmit={this.confirmCodeHandleSubmit}>
                        <div>
                            Confirmation Code
                        </div>
                        <input type="text" name="name" value={this.state.code} onChange={this.confirmationCodeHandle} />
                        <input type="submit" value="Submit" />
                    </form>
                    : <NewPassword email={email} code={code} />}
            </div>
        );
    }
}

export default ConfirmCode