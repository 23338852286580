
const { GoogleSpreadsheet } = require('google-spreadsheet');

const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_URL_ID);

doc.useServiceAccountAuth({
    client_email: process.env.REACT_APP_GOOGLE_CLIENT_EMAIL,
    private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY
});


export async function salesRepGoogleAPI() {
    await doc.loadInfo(); // loads document properties and worksheets

    const sheet = doc.sheetsByIndex[1]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    let rows = await sheet.getRows()

    let dataArray = []

    for (let i = 0; i < rows.length; i++) {
        let row = rows[i]._rawData[0]
        dataArray.push(row)
    }
    // console.log('===?', dataArray)

    return dataArray
}
