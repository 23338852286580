import { Auth } from 'aws-amplify';

const initialState = {
    userName: '',
    userId: "",
    email: ''
}

const currentUser = (state = initialState, action) => {
    const newState = { ...state }

    Auth.currentAuthenticatedUser()
        .then((user) => {
            let attributes = user.attributes.name
            if (user) {
                newState.userName = attributes ? attributes : user.username
                newState.userId = user.attributes.sub
                newState.email = user.attributes.email
            }
        })
        .catch((err) => console.log(err));

    return newState
};

export default currentUser
