export async function locationApi() {
  try {
    const result = await fetch(
      `https://vmbg9f3xok.execute-api.us-west-2.amazonaws.com/prod/signup-locations`
    );
    // const result = await fetch(
    //   `https://bc4oqond25.execute-api.us-west-2.amazonaws.com/dev/signup-locations`
    // );
    const data = await result.json();
    return data;
  } catch (e) {
    return null;
  }
}
