/* eslint-disable */
import React, { Component } from "react";
import { Modal } from 'react-bootstrap'
import modalstyling from './modalStyling'

class CountyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { listofcounties, statedisplayname, countyselected } = this.props
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='text-center' style={modalstyling.titleStyle}>Select your county</div>
                    <br />
                    {
                        listofcounties.length > 0
                            ? <div>
                                <div className="container text-center">
                                    <div className="row" style={modalstyling.centerContainer}>
                                        {listofcounties.map((counties, i) => {
                                            if (counties.stateDisplayName === statedisplayname) {
                                                return (
                                                    <div key={i} className="col-md-3" onClick={() => countyselected(counties)} style={modalstyling.cardBoxLayout}>
                                                        <br />
                                                        <div onClick={this.props.onHide} >
                                                            {counties.countyDisplayName}
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}


export default CountyModal

