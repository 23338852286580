const modalstyling = {
    centerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleStyle: {
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontSize: '2vw',
    },
    locationDisplay: {
        color: '#848484',
    },
    cardBoxLayout: {
        fontSize: '95%',
        border: '1px solid #2A9B8D',
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '100%',
        // height: '51px',
        margin: "1%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#2A9B8D'
    },
};

export default modalstyling