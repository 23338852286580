import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_TOKEN = process.env.REACT_APP_STRIPE_TOKEN;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_TOKEN);

export default function Index(props) {
  return props.clientSecret ? (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: props.clientSecret }}
    >
      {props.children}
    </Elements>
  ) : (
    <div>
      {props.children}
    </div>
  );
}
