const initialState = {
  state: {
    isStateSelected: false,
    stateSelected: '',
    stateDisplayName: '',
    counties: []
  }
}

const stateSelected = (state = initialState, action) => {
  const newState = {
    ...state
  }

  if (action.type === 'STATE_SELECTED') {

    newState.state = action.selectedState
  }

  return newState

};


export default stateSelected
