import axios from 'axios';

const initialState = {
    locationProfiles: []
}

const locationProfileApi = async (state = initialState, action) => {
    try {
        const newState = { ...state }

        let locationProfileApiCall = await axios.get(
            process.env.REACT_APP_GATEWAY_API_LOCATIONS,
        );

        if (locationProfileApiCall) {
            let locationProfilesApi = locationProfileApiCall.data.locations

            locationProfilesApi.sort(function (a, b) {
                return a.stateName.toLowerCase() > b.stateName.toLowerCase() ? 1 : -1;
            });
            newState.locationProfiles = [...locationProfilesApi]
        }

        return newState

    } catch (e) {
        return null;
    }

};


export default locationProfileApi
