const paymentInformationStyling = {
    stripeElementstyle: {
        base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '25px',
            // fontWeight: 300,
            fontSize: '15px',
            '::placeholder': {
                color: '#C4C4C4',
            },
        },
    },
    inputStyle: {
        width: '100%', paddingTop: '2%', paddingBottom: '2%', paddingLeft: '1.5%',
        border: '1px solid #E0E0E0'
    },
    stripeContainer: {
        borderRadius: '0px 0px 20px 20px',
        padding: '2%',
        backgroundColor: 'white',
    },
    titleFont: {
        color: '#30313D',
        fontSize: '15px',
    },
    inputTitleStyle: {
        color: '#848484',
        fontWeight: 'bold',
        fontSize: '1.4vw'
    },
    redAsterisk: {
        color: '#ff0000'
    }
}

export default paymentInformationStyling;