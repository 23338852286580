import React, { Component } from 'react';
import paymentInformationStyling from './paymentInformationStyling'
import { PaymentElement } from '@stripe/react-stripe-js';


class PaymentInformation extends Component {
    render() {
        const { creditCardNameHandle, firstName, lastName, creditCardName, stripeHandle, elements, stripe } = this.props

        stripeHandle(elements, stripe)

        return (
            <div
                className="col-12"
                style={paymentInformationStyling.stripeContainer}
            >
                {/*  */}
                <div className="container" style={{ paddingBottom: '5%' }}>
                    <div className="row">
                        <div className="col col-12 text-left">
                            <div style={{ paddingTop: '5%' }}></div>
                            <div style={paymentInformationStyling.titleFont}>Name on card <span style={paymentInformationStyling.redAsterisk} >*</span></div>
                            <input
                                className='placeholderTextStripe'
                                placeholder={creditCardName.length > 0 ? `${creditCardName}` : `${firstName} ${lastName}`}
                                onChange={creditCardNameHandle}
                                style={paymentInformationStyling.inputStyle}
                            />
                        </div>
                    </div>

                    <div style={{ paddingTop: '2%' }}></div>

                    <PaymentElement
                        onChange={this.props.onChange}
                        options={{
                            hidePostalCode: true,
                            fields: {
                                billingDetails: {
                                    address: {
                                        country: 'never',
                                        postalCode: 'never'
                                    }
                                }
                            }
                        }}
                    />

                </div>
            </div>
        );
    }
}

export default PaymentInformation;
