/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import { Auth, Hub } from 'aws-amplify';

import NotAnEmployee from './components/non-employee/errorPage'
import InitialForm from "./components/initialForm/initialForm";

import EnterEmail from './components/new_password/forgotPassword'

import styles from "./AuthStyling";
import googleLogo from './googleLogo.png'

import welcomeIcon from './welcomeIcon.png'

import './index.css'

const initialFormState = {
  username: "",
  password: "",
  email: "",
  confirmationCode: "",
};

async function signInGoogle() {
  try {
    await Auth.federatedSignIn({
      provider: 'Google',
      // customState: 'yolo'
    })
    console.log("sign in with google success!!!");
  } catch (err) {
    console.log("error signing up..", err);
  }
}

function updateFormStateReducer(state, action) {
  switch (action.type) {
    case "updateFormState":
      return {
        ...state,
        [action.e.target.name]: action.e.target.value,
      };
    default:
      return state;
  }
}

function checkUser() {
  Auth.currentAuthenticatedUser()
    .then((user) => console.log(user, 'GROUP =>', user.signInUserSession.accessToken.payload["cognito:groups"]))
    .catch((err) => console.log(err));
}

async function signUp({ username, password, email }, updateFormType) {
  try {
    await Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: email,
        'custom:isSalesRep': 'true'
      },
    });
    console.log("sign up success!");
    updateFormType("confirmSignUp");
  } catch (err) {
    console.log("error signing up..", err);
  }
}

async function confirmSignUp({ username, confirmationCode }, updateFormType) {
  try {
    await Auth.confirmSignUp(username, confirmationCode);
    console.log("confirm sign up success!");
    updateFormType("signIn");
  } catch (err) {
    console.log("error signing up..", err);
  }
}

function SignIn(props) {
  return (
    <div>
      <div className="">
        <div className="row text-center">
          <div className="col"></div>
          <div className="col" style={styles.center_auth_form}>
            <div>
              <h1 style={styles.home_title_font}>Welcome Back!</h1>

              <div>
                <input
                  name="username"
                  onChange={(e) => {
                    e.persist();
                    props.updateFormState(e);
                  }}
                  style={styles.input}
                  placeholder="Username or Email"
                />
              </div>
              <div>
                <input
                  type="password"
                  name="password"
                  onChange={(e) => {
                    e.persist();
                    props.updateFormState(e);
                  }}
                  style={styles.input}
                  placeholder="Password"
                />
              </div>
              <div>
                <br />
                <button style={styles.button} onClick={props.signIn}>
                  Sign In
                </button>
              </div>
              <div>
                <button
                  className="border"
                  style={styles.button_google}
                  id='submitButton'
                  onClick={signInGoogle}
                >
                  <img style={styles.google_icon} src={googleLogo} alt="Logo" />
                  <span className="white_color">{"-"}</span>
                  Sign In with Google
                </button>
                <EnterEmail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{/* <br />
<div>
  <br />
  <br />
  <br />
  <button onClick={checkUser}>Check User Status</button>
</div> */}

function SignUp(props) {
  return (
    <div className=''>
      <div style={styles.centerLoginContainer}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12" style={styles.centerGoogleLogin}>
              <img src={welcomeIcon} alt="Logo" style={styles.welcomeIcon} />
            </div>
            <div className="col-sm-12">
              <div className="container"
                style={styles.signInContainer}>
                <div className="row">
                  <div className="col-sm">

                    <br />

                    <button
                      className="google_buttom_border border"
                      style={styles.button_google}
                      id='googleLogin'
                      onClick={signInGoogle}
                    >
                      <img style={styles.google_icon}
                        src={googleLogo} alt="Logo"
                      />
                      <span className="white_color">{` `}</span>
                      Google
                    </button>

                    <br />
                    <br />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

function ConfirmSignUp(props) {
  return (
    <div style={styles.container}>
      <input
        name="confirmationCode"
        placeholder="Confirmation Code"
        onChange={(e) => {
          e.persist();
          props.updateFormState(e);
        }}
        style={styles.input}
      />
      <button onClick={props.confirmSignUp} style={styles.button}>
        Confirm Sign Up
      </button>
    </div>
  );
}

function App() {
  const [isEmployee, setEmployeeAccess] = useState(false);
  const [authState, setAuthState] = useState("");
  const [user, setUser] = useState();
  const [formType, updateFormType] = useState("signUp");
  const [formState, updateFormState] = useReducer(
    updateFormStateReducer,
    initialFormState
  );

  // in useEffect, we create the listener
  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setAuthState("signedIn");
      }
      if (payload.event === "signOut") {
        setAuthState(payload.event);
      }
    });

    if (authState === "signedIn") {
      updateFormType("signedIn");
    }
    if (authState === "signOut") {
      updateFormType("signOut");
    }

    // check if user is an Recyclops employee
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let salesRep = user.signInUserSession.accessToken.payload[["cognito:groups"][0]]
        let isSalesRep = false

        if (salesRep) {
          for (let key in salesRep) {
            if (salesRep[key] === process.env.REACT_APP_SALES_REP) {
              isSalesRep = true
            }
          }
        }
        if (user.signInUserSession.accessToken.payload["cognito:groups"][0] === process.env.REACT_APP_EMPLOYEE_GROUP
          || user.signInUserSession.accessToken.payload["cognito:groups"][1] === process.env.REACT_APP_EMPLOYEE_GROUP
          || isSalesRep) {
          setEmployeeAccess(true)
        }

      })
      .catch((err) => console.log(err));

    Auth.currentAuthenticatedUser()
      .then((user) => setUser({ user }, setAuthState("signedIn")))
      .catch((err) => console.log(err));

  }, []);

  async function signIn(username, password) {
    const user = await Auth.signIn(username, password);
    setAuthState("signedIn");
  }

  async function signOut() {
    const user = await Auth.signOut();
    setAuthState("signOut");
  }

  function renderForm() {
    switch (formType) {
      case "signUp":
        return (
          <SignUp
            signUp={() => signUp(formState, updateFormType)}
            updateFormState={(e) =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType)}
            updateFormState={(e) =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      case "signIn":
        return (
          <SignIn
            signIn={() => signIn(formState)}
            updateFormState={(e) =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="App">
      {authState === "signedIn" ? (
        <div>
          {isEmployee
            ? <div>
              {/* <button onClick={checkUser}>Check User Status</button>
              <button onClick={() => signOut()}>Sign out</button> */}
              <InitialForm signOut={() => signOut()} />
            </div>
            :
            <div>
              <button onClick={checkUser}>Check User Status</button>
              <NotAnEmployee />
            </div>
          }
        </div>
      ) : (
        <div>
          <div id='signIn'>{renderForm(formState)}</div>
        </div>
      )}
    </div>
  );
}

export default App
