/* eslint-disable */
import React, { Component } from "react";
import { Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import serviceStyling from './serviceStyling'
import CustomerSignupForm from '../customer-signup/customerSignupForm'
import InformationSection from './informationSection'
import { Icon } from '@iconify/react';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAreaServceData: [],

            testAnnualRateResult: '',

            stateDisplayName: '',
            countyDisplayName: '',
            locationDisplayName: '',
            doesLocationExist: false,

            basicPickupSchedules: {
                everyOther: false,
                monthly: false,
                onDemand: false,
                twiceMonthly: false,
                weekly: false
            },
            glassPickupSchedules: {
                everyOther: false,
                monthly: false,
                onDemand: false,
                twiceMonthly: false,
                weekly: false
            },
            cardboardPickupSchedules: {
                everyOther: false,
                monthly: false,
                onDemand: false,
                twiceMonthly: false,
                weekly: false
            },
            compostPickupSchedules: {
                everyOther: false,
                monthly: false,
                onDemand: false,
                twiceMonthly: false,
                weekly: false
            },
            kitchenOrganicsPickupSchedules: {
                everyOther: false,
                monthly: false,
                onDemand: false,
                twiceMonthly: false,
                weekly: false
            },
            basicSetupCost: 0,

            monthlyRate: {
                everyOther: 0,
                monthly: 0,
                onDemand: 0,
                twiceMonthly: 0,
                weekly: 0
            },

            annualRate: {
                everyOther: 0,
                monthly: 0,
                onDemand: 0,
                twiceMonthly: 0,
                weekly: 0
            },

            glassMonthlyRate: {
                everyOther: 0,
                monthly: 0,
                onDemand: 0,
                twiceMonthly: 0,
                weekly: 0
            },
            serviceOfferings: {
                basic: false,
                cardboard: false,
                compost: false,
                glass: false,
                kitchenOrganics: false
            },

            basePrice: 0,
            annualBasePrice: 0,
            glassPrice: 0,

            toggleCustomerSignUp: false,

            totalSum: 0,
            annualTotalSum: 0,

            typeOfServiceSelected: '',

            monthlyGlassServiceSelected: "",
            glassMonthlyOnlyProvidedCost: 0,

            onStepOne: true,
            onStepTwo: false,
            onStepThree: false,

            address1: '',
            address2: '',
            city: '',
            stateName: '',
            zipcode: '',
            firstName: '',
            lastName: '',
            email: '',
            emailConfirmation: '',
            phoneNumber: '',
            specialAddressInstructions: '',
            salesRepName: '',
            specialNotes: '',
            reviewedAndConfirm: false,
            interestedInLearningMore: false,
            interestedInBecomingADriver: false,

            areaOfService: '',
            pickUpSchedule: '',
            glassServiceSelected: false,
            noSetUpFee: false,
            oneMonthFree: false,
            discountSetupFee: '',
            isDiscountSetupFeeVoided: false,
            paymentMethod: '',
            paymentFrequency: '',

            logout: false,

            selectedSalesRep: '',
            pickupStartDate: '',

            customerSignupFormCompleted: false,
            serviceInformationFormCompleted: false,

            errorMsg: false,

            summaryPageSelected: false,

            hubspotContactId: '',
            hubspotDealId: ''
        };
    }

    componentDidMount() {
        const { selectedAreaServceData } = this.props
        const { countyDisplayName, locationDisplayName } = this.state
        // if county or location state changes
        // selectedAreaServceData[x].countyDisplayName !== countyDisplayName ||

        for (let i = 0; i < selectedAreaServceData.length; i++) {
            // if (selectedAreaServceData[i].locationDisplayName !== locationDisplayName) {
            let pickupSchedules = selectedAreaServceData[i].pickupSchedules
            let basicSetup = selectedAreaServceData[i].residentialRates === null ? 0 : selectedAreaServceData[i].residentialRates.BasicSetup
            let serviceOfferingsCost = selectedAreaServceData[i].residentialRates === null ? null : selectedAreaServceData[i].residentialRates.Services
            let serviceOfferings = selectedAreaServceData[i].services

            this.setState({
                stateDisplayName: selectedAreaServceData[i].stateDisplayName,
                countyDisplayName: selectedAreaServceData[i].countyDisplayName,
                locationDisplayName: selectedAreaServceData[i].locationDisplayName,
                doesLocationExist: selectedAreaServceData[i].doesLocationExist
            })

            // pickupSchedules
            if (pickupSchedules.Basic) {
                this.setState({
                    basicPickupSchedules: {
                        everyOther: pickupSchedules.Basic.EveryOther,
                        monthly: pickupSchedules.Basic.Monthly,
                        onDemand: pickupSchedules.Basic.OnDemand,
                        twiceMonthly: pickupSchedules.Basic.TwiceMonthly,
                        weekly: pickupSchedules.Basic.Weekly
                    }
                })
            }
            if (pickupSchedules.Glass) {
                this.setState({
                    glassPickupSchedules: {
                        everyOther: pickupSchedules.Glass.EveryOther,
                        monthly: pickupSchedules.Glass.Monthly,
                        onDemand: pickupSchedules.Glass.OnDemand,
                        twiceMonthly: pickupSchedules.Glass.TwiceMonthly,
                        weekly: pickupSchedules.Glass.Weekly
                    }
                })
            }
            if (pickupSchedules.Cardboard) {
                this.setState({
                    cardboardPickupSchedules: {
                        everyOther: pickupSchedules.Cardboard.EveryOther,
                        monthly: pickupSchedules.Cardboard.Monthly,
                        onDemand: pickupSchedules.Cardboard.OnDemand,
                        twiceMonthly: pickupSchedules.Cardboard.TwiceMonthly,
                        weekly: pickupSchedules.Cardboard.Weekly
                    }
                })
            }
            if (pickupSchedules.Compost) {
                this.setState({
                    compostPickupSchedules: {
                        everyOther: pickupSchedules.Compost.EveryOther,
                        monthly: pickupSchedules.Compost.Monthly,
                        onDemand: pickupSchedules.Compost.OnDemand,
                        twiceMonthly: pickupSchedules.Compost.TwiceMonthly,
                        weekly: pickupSchedules.Compost.Weekly
                    }
                })
            }
            if (pickupSchedules.KitchenOrganics) {
                this.setState({
                    kitchenOrganicsPickupSchedules: {
                        everyOther: pickupSchedules.KitchenOrganics.EveryOther,
                        monthly: pickupSchedules.KitchenOrganics.Monthly,
                        onDemand: pickupSchedules.KitchenOrganics.OnDemand,
                        twiceMonthly: pickupSchedules.KitchenOrganics.TwiceMonthly,
                        weekly: pickupSchedules.KitchenOrganics.Weekly
                    }
                })
            }
            // basicSetup price
            if (basicSetup) {
                this.setState({
                    basicSetupCost: basicSetup
                })
            }

            if (serviceOfferingsCost !== null) {
                // service offerings monthly cost
                if (serviceOfferingsCost.Basic) {
                    this.setState({
                        monthlyRate: {
                            everyOther: serviceOfferingsCost.Basic.EveryOther ? serviceOfferingsCost.Basic.EveryOther.MonthlyRate : 0,
                            monthly: serviceOfferingsCost.Basic.Monthly ? serviceOfferingsCost.Basic.Monthly.MonthlyRate : 0,
                            onDemand: serviceOfferingsCost.Basic.OnDemand ? serviceOfferingsCost.Basic.OnDemand.MonthlyRate : 0,
                            twiceMonthly: serviceOfferingsCost.Basic.TwiceMonthly ? serviceOfferingsCost.Basic.TwiceMonthly.MonthlyRate : 0,
                            weekly: serviceOfferingsCost.Basic.Weekly ? serviceOfferingsCost.Basic.Weekly.MonthlyRate : 0
                        },
                        annualRate: {
                            everyOther: serviceOfferingsCost.Basic.EveryOther ? serviceOfferingsCost.Basic.EveryOther.AnnualRate : 0,
                            monthly: serviceOfferingsCost.Basic.Monthly ? serviceOfferingsCost.Basic.Monthly.AnnualRate : 0,
                            onDemand: serviceOfferingsCost.Basic.OnDemand ? serviceOfferingsCost.Basic.OnDemand.AnnualRate : 0,
                            twiceMonthly: serviceOfferingsCost.Basic.TwiceMonthly ? serviceOfferingsCost.Basic.TwiceMonthly.AnnualRate : 0,
                            weekly: serviceOfferingsCost.Basic.Weekly ? serviceOfferingsCost.Basic.Weekly.AnnualRate : 0
                        },
                    })
                }
                // Glass monthly cost
                if (serviceOfferingsCost.Glass) {

                    this.setState({
                        glassMonthlyRate: {
                            everyOther: serviceOfferingsCost.Glass.EveryOther ? serviceOfferingsCost.Glass.EveryOther.MonthlyRate : 0,
                            monthly: serviceOfferingsCost.Glass.Monthly ? serviceOfferingsCost.Glass.Monthly.MonthlyRate : 0,
                            onDemand: serviceOfferingsCost.Glass.OnDemand ? serviceOfferingsCost.Glass.OnDemand.MonthlyRate : 0,
                            twiceMonthly: serviceOfferingsCost.Glass.TwiceMonthly ? serviceOfferingsCost.Glass.TwiceMonthly.MonthlyRate : 0,
                            weekly: serviceOfferingsCost.Glass.Weekly ? serviceOfferingsCost.Glass.Weekly.MonthlyRate : 0
                        }
                    })
                }
            }

            // services we offer
            if (serviceOfferings) {
                this.setState({
                    serviceOfferings: {
                        basic: serviceOfferings.Basic,
                        cardboard: serviceOfferings.Cardboard,
                        compost: serviceOfferings.Compost,
                        glass: serviceOfferings.Glass,
                        kitchenOrganics: serviceOfferings.KitchenOrganics
                    }
                })
            }
        }

        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    hubSpotIdHandler = (hubspotContactId, hubspotDealId) => {
        this.setState({
            hubspotContactId: hubspotContactId,
            hubspotDealId: hubspotDealId
        })
    }

    serviceSelectionHandle = (baseCost, glassCost, typeOfService, annualBaseCost) => {

        let annualBaseCostTotal = annualBaseCost / 12

        this.setState({
            totalSum: 0,
            annualTotalSum: 0,
            basePrice: 0,
            annualBasePrice: 0,
            glassPrice: 0,
            typeOfServiceSelected: 0,
        })

        let totalCost = 0
        let annualTotalCost = 0

        if (glassCost !== 0) {
            totalCost = baseCost + glassCost
            annualTotalCost = annualBaseCostTotal + 7
        }

        this.setState({
            totalSum: totalCost === 0 ? baseCost : totalCost,
            annualTotalSum: annualTotalCost === 0 ? annualBaseCostTotal : annualTotalCost,
            basePrice: baseCost,
            annualBasePrice: annualBaseCostTotal,
            glassPrice: glassCost,
            typeOfServiceSelected: typeOfService
        })

    }
    unSelectServiceHandle = () => {
        this.setState({
            totalSum: 0,
            annualTotalSum: 0,
            typeOfServiceSelected: 0,
        })
    }
    serviceSelectionWhenGlassMonthlyIsOnlyAvailable = (cost, typeOfService) => {
        this.setState({
            glassMonthlyOnlyProvidedCost: cost,
            monthlyGlassServiceSelected: typeOfService
        })
    }
    clearAllServiceSelectedHandle = () => {
        this.setState({
            totalSum: 0,
            annualTotalSum: 0,
            typeOfServiceSelected: 0,
            glassMonthlyOnlyProvidedCost: 0,
            monthlyGlassServiceSelected: ''
        })
    }
    onStepOneHandle = () => {
        this.setState({
            onStepOne: true,
        })
    }
    onStepTwoHandle = () => {
        this.setState({
            onStepTwo: !this.state.onStepTwo,
            onStepThree: false
        })
    }

    onStepThreeHandle = () => {
        this.setState({
            onStepThree: !this.state.onStepThree
        })
    }
    toggleCustomerSignUpHandle = () => {
        const { selectedSalesRep } = this.state

        if (selectedSalesRep.length > 0) {
            this.setState({
                toggleCustomerSignUp: !this.state.toggleCustomerSignUp,
                onStepTwo: false,
                errorMsg: false,
                onStepThree: false
            })
        } else {
            this.setState({
                errorMsg: true
            })
        }

    }
    toggleToServices = () => {
        this.setState({
            toggleCustomerSignUp: !this.state.toggleCustomerSignUp,
            summaryPageSelected: false,
            onStepTwo: false,
            onStepThree: false
        })
    }
    // mobile
    toggleToSummary = () => {
        const { toggleCustomerSignUp, onStepTwo } = this.state

        let onStep1 = false
        let onStep2 = false

        if (toggleCustomerSignUp) {
            onStep1 = true
        }

        if (toggleCustomerSignUp && onStepTwo) {
            onStep1 = true
            onStep2 = true
        }

        this.setState({
            summaryPageSelected: !this.state.summaryPageSelected,
            toggleCustomerSignUp: onStep1,
            onStepTwo: onStep2,
            onStepThree: false
        })
    }

    // CustomerSignupForm
    address1Handle = (e) => {
        this.setState({
            address1: e.target.value
        })
    }
    address2Handle = (e) => {
        this.setState({
            address2: e.target.value
        })
    }
    cityHandle = (e) => {
        this.setState({
            city: e.target.value
        })
    }
    stateNameHandle = (e) => {
        this.setState({
            stateName: e.target.value
        })
    }
    zipCodeHandle = (e) => {
        this.setState({
            zipcode: e.target.value
        })
    }
    firstNameHandle = (e) => {
        this.setState({
            firstName: e.target.value
        })
    }
    lastNameHandle = (e) => {
        this.setState({
            lastName: e.target.value
        })
    }
    emailHandle = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    emailConfirmationHandle = (e) => {
        this.setState({
            emailConfirmation: e.target.value
        })
    }
    phoneNumberHandle = (e) => {
        this.setState({
            phoneNumber: e.target.value
        })
    }
    specialAddressInstructionsHandle = (e) => {
        this.setState({
            specialAddressInstructions: e.target.value
        })
    }
    salesRepNameHandle = (e) => {
        this.setState({
            salesRepName: e.target.value
        })
    }
    specialNotesHandle = (e) => {
        this.setState({
            specialNotes: e.target.value
        })
    }
    reviewedAndConfirmHandle = (e) => {
        this.setState({
            reviewedAndConfirm: !this.state.reviewedAndConfirm
        })
    }
    interestedInBecomingADriverHandle = (e) => {
        this.setState({
            interestedInBecomingADriver: !this.state.interestedInBecomingADriver
        })
    }
    interestedInLearningMoreHandle = (e) => {
        this.setState({
            interestedInLearningMore: !this.state.interestedInLearningMore
        })
    }
    customerSignupFormHandle = () => {
        this.setState({
            customerSignupFormCompleted: true,
        })
    }
    customerSignupFormDisableHandle = () => {
        this.setState({
            customerSignupFormCompleted: false,
        })
    }
    serviceInformationFormHandle = () => {
        // clear customer data to restart form where sales rep left off (page where sales rep name and location has been selected)
        this.setState({
            serviceInformationFormCompleted: true,
            typeOfServiceSelected: '',
            toggleCustomerSignUp: !this.state.toggleCustomerSignUp,
            onStepOne: true,
            onStepTwo: false,

            address1: '',
            address2: '',
            city: '',
            stateName: '',
            zipcode: '',

            firstName: '',
            lastName: '',
            email: '',
            emailConfirmation: '',
            phoneNumber: '',

            interestedInLearningMore: false,
            interestedInBecomingADriver: false,
            specialAddressInstructions: '',
            specialNotes: '',

            noSetUpFee: false,
            oneMonthFree: false,
            discountSetupFee: '',
            isDiscountSetupFeeVoided: false,
            paymentMethod: '',
            paymentFrequency: '',

            totalSum: 0,
            annualTotalSum: 0,
        })
    }

    //  ServiceInformationForm
    areaOfServiceHandle = (e) => {
        this.setState({
            areaOfService: e.target.value
        })
    }
    pickUpScheduleHandle = (e) => {
        this.setState({
            pickUpSchedule: e.target.value
        })
    }
    glassServiceHandle = () => {
        this.setState({
            glassServiceSelected: !this.state.glassServiceSelected
        })
    }
    noSetUpFeeHandle = () => {
        this.setState({
            noSetUpFee: !this.state.noSetUpFee
        })
    }
    oneMonthFreeHandle = () => {
        this.setState({
            oneMonthFree: !this.state.oneMonthFree
        })
    }
    discountHandle = (discount) => {
        if (discount === 'noSetUpFee') {
            this.setState({
                noSetUpFee: !this.state.noSetUpFee
            })
        }
        if (discount === 'oneMonthFree') {
            this.setState({
                oneMonthFree: !this.state.oneMonthFree
            })
        }
    }
    discountSetupFeeHandle = (e) => {

        this.setState({
            discountSetupFee: Number(e.target.value),
        })

        if (e.target.value !== '0') {
            this.setState({
                isDiscountSetupFeeVoided: true
            })
        } else {
            this.setState({
                isDiscountSetupFeeVoided: false
            })
        }
    }
    paymentMethodHandle = (e) => {
        this.setState({
            paymentMethod: e.target.value
        })
    }
    paymentFrequencyHandle = (e) => {
        this.setState({
            paymentFrequency: e.target.value
        })
    }
    selectSalesRepFeeHandle = (e) => {
        this.setState({
            selectedSalesRep: e.target.value
        })
    }
    setPickupStartDate = (data) => {
        this.setState({
            pickupStartDate: data
        })
    }

    render() {
        const { annualTotalSum, annualRate, errorMsg, serviceInformationFormHandle, serviceInformationFormCompleted, customerSignupFormCompleted, customerSignupFormDisableHandle,
            pickupStartDate, typeOfService, isDiscountSetupFeeVoided, discountSetupFee, noSetUpFee, oneMonthFree, paymentFrequency, selectedSalesRep, logout, onStepOne, onStepTwo,
            onStepThree, monthlyGlassServiceSelected, typeOfServiceSelected, glassMonthlyOnlyProvidedCost, totalSum, toggleCustomerSignUp,
            doesLocationExist, basicPickupSchedules, glassPickupSchedules, basicSetupCost, monthlyRate, glassMonthlyRate, serviceOfferings, stateDisplayName,
            countyDisplayName, locationDisplayName, basePrice, annualBasePrice, glassPrice, summaryPageSelected } = this.state

        const { selectedAreaServceData, locationSelectionCompletedHandle, saleReps } = this.props

        // calculate total cost with or without discounts
        let absoluteTotalSum = 0
        let annualAbsoluteTotalSum = 0

        let freeMonthDiscount = 0
        let annualFreeMonthDiscount = 0

        if (paymentFrequency === 'quarterly') {
            absoluteTotalSum = totalSum * 3
        }
        if (paymentFrequency === 'annually') {
            absoluteTotalSum = totalSum * 12
            annualAbsoluteTotalSum = annualTotalSum * 12
        }
        if (paymentFrequency === 'monthly') {
            absoluteTotalSum = totalSum
            annualAbsoluteTotalSum = annualTotalSum * 12
        }
        if (oneMonthFree) {
            freeMonthDiscount = absoluteTotalSum - totalSum
            annualFreeMonthDiscount = annualAbsoluteTotalSum - annualTotalSum
        } else {
            freeMonthDiscount = absoluteTotalSum
            annualFreeMonthDiscount = annualAbsoluteTotalSum
        }

        // when user selects type of Discount setup fee %
        let discountByPercentage = 0

        if (discountSetupFee > 0) {
            discountByPercentage = basicSetupCost - (basicSetupCost * discountSetupFee);
        }

        return (
            // Only glass box option has a condition to capture when glass is the only option for MONTHY. That logic has not been updated for every other or weekly
            <div>
                <div >
                    {/*  Only glass box option has a condition to capture when glass is the only option for MONTHY. That logic has not been updated for every other or weekly */}

                    <div className='row'>
                        <div className="col-12" style={serviceStyling.headerStyle} >
                            <div className="container">
                                <div className="row" style={serviceStyling.headerFont}>
                                    <div className="col-sm" id='Recyclops'>
                                        Recyclops
                                        {/* Total Sales */}
                                    </div>
                                    <div className="col-sm text-center" id='locationSelectionCompletedHandle' onClick={() => locationSelectionCompletedHandle()}>
                                        {doesLocationExist ? `${locationDisplayName}, ${stateDisplayName}` : `${countyDisplayName}, ${stateDisplayName}`}
                                    </div>
                                    <div className="col-sm text-right">
                                        <div>
                                            <div>
                                                <select
                                                    id='selectSalesRep'
                                                    onChange={this.selectSalesRepFeeHandle}
                                                    style={serviceStyling.dropDownStyling}
                                                >
                                                    <option className='text-center' value="" >Select Sales Rep</option>
                                                    {saleReps
                                                        ? saleReps.map((SalesRep, i) => {
                                                            return <option key={i} className='text-center' value={SalesRep} >{SalesRep}</option>
                                                        })
                                                        : ''}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <span onClick={() => locationSelectionCompletedHandle()}>
                        {`Click Back to location selection`}
                    </span> */}

                    {/* <span onClick={() => this.toggleCustomerSignUpHandle()}>
                        {` View services: ${!toggleCustomerSignUp}`}
                    </span> */}

                    {toggleCustomerSignUp
                        ? <div className='row'>
                            <div className="col-12">
                                <div style={serviceStyling.headerSpacing}></div>
                            </div>

                            <div className="col-sm-2 col-lg-2 hide_if_mobile_screen">
                                <div style={serviceStyling.columnRightAndLeftContainer}>
                                    <div>
                                        <div className='container text-center' >
                                            <br />
                                            <div style={serviceStyling.titleFont} id='infoTest'>
                                                Information
                                            </div>

                                            <br />

                                            <InformationSection
                                                typeOfServiceSelected={typeOfServiceSelected}
                                                monthlyRate={monthlyRate}
                                                glassPickupSchedules={glassPickupSchedules}
                                                glassMonthlyRate={glassMonthlyRate}
                                                basicPickupSchedules={basicPickupSchedules}
                                            />

                                            <br />
                                        </div>
                                        <div className="container">
                                            <div style={serviceStyling.desciptionFont}>
                                                <div>
                                                    {typeOfServiceSelected === 'monthly' || typeOfServiceSelected === 'monthlyGlassOnly' || typeOfServiceSelected === 'monthly+Glass'
                                                        ? `- Monthly pickups` : ''
                                                    }
                                                    {typeOfServiceSelected === 'weekly' || typeOfServiceSelected === 'weekly+Glass'
                                                        ? `- Weekly pickups` : ''
                                                    }
                                                    {typeOfServiceSelected === 'everyOtherWeek' || typeOfServiceSelected === 'everyOtherWeek+Glass'
                                                        ? `- Every other pickups` : ''
                                                    }
                                                </div>
                                                <div>- {`$${basicSetupCost} one time setup fee`}</div>
                                                <div>- Bags included every month</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* harris red */}
                            <div className="col-lg-8 col-11" style={serviceStyling.columnCenterContainer}>

                                {/* harris summary 1*/}
                                <div className="hide_if_not_mobile_screen">
                                    {summaryPageSelected
                                        ? <div className='container'>
                                            <br />

                                            <div className="">
                                                <span style={{ fontSize: '20px' }}>
                                                    <i className="show_if_mobile_screen">
                                                        <Icon icon="bi:clipboard2-data-fill" style={{ fontSize: '40px' }} />
                                                    </i>
                                                </span>
                                                <span style={serviceStyling.formTitleFont}>
                                                    {` Summary`}
                                                </span>
                                            </div>

                                            <div style={{ paddingTop: '5%' }}></div>

                                            <div className="row">
                                                <div className="col-1"> </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className='text-left'>Services:</div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className='text-left'>
                                                                <div >
                                                                    {typeOfServiceSelected === 'everyOtherWeek' ||
                                                                        typeOfServiceSelected === 'weekly' ||
                                                                        typeOfServiceSelected === 'monthly'
                                                                        ? 'Standard Pickup' : ''}

                                                                    {typeOfServiceSelected !== 'monthlyGlassOnly' &&
                                                                        (typeOfServiceSelected === 'everyOtherWeek+Glass' ||
                                                                            typeOfServiceSelected === 'weekly+Glass' ||
                                                                            typeOfServiceSelected === 'monthly+Glass')
                                                                        ? 'Standard Pickup + Glass'
                                                                        : ''
                                                                    }
                                                                    {typeOfServiceSelected === 'monthlyGlassOnly' ? 'Glass Only' : ''}
                                                                </div>

                                                                <div className='text-left' ></div>

                                                                {paymentFrequency === 'quarterly'
                                                                    ? <span className='text-left' >
                                                                        {` $${glassMonthlyOnlyProvidedCost !== 0 ? (totalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : totalSum.toFixed(2)} / month`}
                                                                    </span>
                                                                    : ''
                                                                }

                                                                {paymentFrequency === 'monthly'
                                                                    ? <span className='text-left' >
                                                                        {` $${glassMonthlyOnlyProvidedCost !== 0 ? (totalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : totalSum.toFixed(2)} / month`}
                                                                    </span>
                                                                    : ''
                                                                }

                                                                {paymentFrequency === 'annually'
                                                                    ? <span className='text-left' >
                                                                        {` $${glassMonthlyOnlyProvidedCost !== 0 ? (annualTotalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : annualTotalSum.toFixed(2)} / month`}
                                                                    </span>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div>
                                                                <div>
                                                                    <div className='text-center'>

                                                                        {paymentFrequency === 'quarterly'
                                                                            ? <div>
                                                                                <div style={{ fontWeight: 'bold' }}>
                                                                                    {/* <div style={{ color: '#848484' }}>{`Subsequent payments`}</div> */}
                                                                                    {`$${absoluteTotalSum.toFixed(2)}`}
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>

                                                                    <div className='text-center'>

                                                                        {paymentFrequency === 'monthly'
                                                                            ? <div>
                                                                                <div style={{ fontWeight: 'bold' }}>
                                                                                    {/* <div style={{ color: '#848484' }}>{`Subsequent payments`}</div> */}
                                                                                    {`$${absoluteTotalSum.toFixed(2)}`}
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>

                                                                    <div className='text-center' >
                                                                        {paymentFrequency === 'annually'
                                                                            ? <div>
                                                                                <div style={{ fontWeight: 'bold' }}>
                                                                                    {/* <div style={{ color: '#848484' }}>{`Subsequent payments`}</div> */}
                                                                                    {`$${annualAbsoluteTotalSum.toFixed(2)}`}
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ paddingTop: '2%' }}></div>

                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className='text-left' >{`Pickup Frequency: `}</div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className='text-left'>
                                                                <div >
                                                                    {typeOfServiceSelected === 'everyOtherWeek' || typeOfServiceSelected === 'everyOtherWeek+Glass' ? 'Every other' : ''}
                                                                    {typeOfServiceSelected === 'weekly' || typeOfServiceSelected === 'weekly+Glass' ? `Weekly` : ''}
                                                                    {typeOfServiceSelected === 'monthly' || typeOfServiceSelected === 'monthly+Glass' || typeOfServiceSelected === 'monthlyGlassOnly' ? 'Monthly' : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4"></div>
                                                    </div>

                                                    <div style={{ paddingTop: '2%' }}></div>

                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className='text-left' >
                                                                {paymentFrequency === 'quarterly' || paymentFrequency === 'annually' || paymentFrequency === 'monthly' ? `Recurring Billing: ` : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div>
                                                                <div>
                                                                    <div className='text-left' >
                                                                        {paymentFrequency === 'quarterly'
                                                                            ? <div>
                                                                                <div>{`Quarterly`}</div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>

                                                                    <div className='text-left' >

                                                                        {paymentFrequency === 'monthly'
                                                                            ? <div>
                                                                                <div>{`Monthly`}</div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>

                                                                    <div className='text-left' >
                                                                        {paymentFrequency === 'annually'
                                                                            ? <div>
                                                                                <div>{`Annually`}</div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4"> </div>
                                                    </div>

                                                    <div style={{ paddingTop: '2.5%' }}></div>

                                                    <div>
                                                        <div className='text-left' >
                                                            {noSetUpFee || oneMonthFree || isDiscountSetupFeeVoided
                                                                ? <div>
                                                                    <div style={serviceStyling.discountTitleDividerMobile}>s</div>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>

                                                    <div style={{ paddingTop: '2%' }}></div>

                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className='text-left'>
                                                                {noSetUpFee || oneMonthFree || isDiscountSetupFeeVoided
                                                                    ? <div>
                                                                        <div>{`Discount: `}</div>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className='text-left' >
                                                                <div className='text-left' >
                                                                    {
                                                                        paymentFrequency.length > 0
                                                                            ? <div>
                                                                                {noSetUpFee
                                                                                    ? <div>
                                                                                        <div>
                                                                                            {` Setup fee`}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency === 'quarterly'
                                                                            ? <div>
                                                                                {oneMonthFree
                                                                                    ? <div>
                                                                                        <div>
                                                                                            {`One month free`}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency === 'monthly'
                                                                            ? <div>
                                                                                {oneMonthFree
                                                                                    ? <div>
                                                                                        <div>
                                                                                            {`One month free`}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency === 'annually'
                                                                            ? <div>
                                                                                {oneMonthFree
                                                                                    ? <div>
                                                                                        <div>
                                                                                            {`One month free`}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency.length > 0
                                                                            ? <div>
                                                                                {isDiscountSetupFeeVoided
                                                                                    ? <div>
                                                                                        <div>
                                                                                            {`Off setup fee`}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className='text-center'>
                                                                <div className='text-center' >
                                                                    {
                                                                        paymentFrequency.length > 0
                                                                            ? <div>
                                                                                {noSetUpFee
                                                                                    ? <div>
                                                                                        <div style={{ fontWeight: 'bold' }}>
                                                                                            {`-$${basicSetupCost}`}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency === 'quarterly'
                                                                            ? <div>
                                                                                {oneMonthFree
                                                                                    ? <div>
                                                                                        <div style={{ fontWeight: 'bold' }}>
                                                                                            {`-$${totalSum} `}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency === 'monthly'
                                                                            ? <div>
                                                                                {oneMonthFree
                                                                                    ? <div>
                                                                                        <div style={{ fontWeight: 'bold' }}>
                                                                                            {`-$${totalSum} `}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency === 'annually'
                                                                            ? <div>
                                                                                {oneMonthFree
                                                                                    ? <div>
                                                                                        <div style={{ fontWeight: 'bold' }}>
                                                                                            {`-$${annualTotalSum} `}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        paymentFrequency.length > 0
                                                                            ? <div>
                                                                                {isDiscountSetupFeeVoided
                                                                                    ? <div>
                                                                                        <div style={{ fontWeight: 'bold' }}>
                                                                                            {`-${discountSetupFee * 100}% `}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            : ''
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ paddingTop: '2%' }}></div>

                                                    <div>
                                                        <div style={serviceStyling.discountTitleDividerMobile}>s</div>
                                                    </div>

                                                    <div style={{ paddingTop: '2%' }}></div>

                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div>
                                                                {paymentFrequency.length > 0 ? 'Total Sum' : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-4 text-left" >
                                                            <div>{paymentFrequency.length > 0 ? `First Payment` : ''}</div>
                                                        </div>

                                                        <div className="col-4" >
                                                            <div className='text-center'>
                                                                {
                                                                    paymentFrequency === 'quarterly' && !isDiscountSetupFeeVoided
                                                                        ? <div style={{ fontWeight: 'bold' }} >
                                                                            {noSetUpFee
                                                                                ? `$${freeMonthDiscount > 0
                                                                                    ? (freeMonthDiscount - basicSetupCost).toFixed(2) > 0 ? (freeMonthDiscount - basicSetupCost).toFixed(2) : '0.00'
                                                                                    : (absoluteTotalSum - basicSetupCost).toFixed(2) > 0 ? (absoluteTotalSum - basicSetupCost).toFixed(2) : '0.00'}`
                                                                                : `$${freeMonthDiscount > 0
                                                                                    ? freeMonthDiscount.toFixed(2) > 0 ? freeMonthDiscount.toFixed(2) : '0.00'
                                                                                    : absoluteTotalSum.toFixed(2) > 0 ? absoluteTotalSum.toFixed(2) : '0.00'}`
                                                                            }
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    paymentFrequency === 'monthly' && !isDiscountSetupFeeVoided
                                                                        ? <div style={{ fontWeight: 'bold' }} >
                                                                            {noSetUpFee
                                                                                ? `$${freeMonthDiscount > 0
                                                                                    ? (freeMonthDiscount - basicSetupCost).toFixed(2) > 0
                                                                                        ? (freeMonthDiscount - basicSetupCost).toFixed(2)
                                                                                        : '0.00'
                                                                                    : Math.sign(basicSetupCost - (absoluteTotalSum - basicSetupCost)) === 0
                                                                                        ? (absoluteTotalSum - basicSetupCost).toFixed(2)
                                                                                        : '0.00'}`
                                                                                : freeMonthDiscount > 0
                                                                                    ? freeMonthDiscount.toFixed(2) > 0
                                                                                        ? freeMonthDiscount.toFixed(2)
                                                                                        : '0.00'
                                                                                    : `$${basicSetupCost.toFixed(2) - basicSetupCost.toFixed(2)}`}
                                                                            {/* <div style={serviceStyling.summaryTitleFont}>{`First Payment`}</div> */}
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    paymentFrequency === 'annually' && !isDiscountSetupFeeVoided
                                                                        ? <div style={{ fontWeight: 'bold' }} >
                                                                            {noSetUpFee
                                                                                ? `$${annualFreeMonthDiscount > 0
                                                                                    ? (annualFreeMonthDiscount - basicSetupCost).toFixed(2) > 0 ? (annualFreeMonthDiscount - basicSetupCost).toFixed(2) : '0.00'
                                                                                    : (annualAbsoluteTotalSum - basicSetupCost).toFixed(2) > 0 ? (annualAbsoluteTotalSum - basicSetupCost).toFixed(2) : '0.00'}`
                                                                                : `$${annualFreeMonthDiscount > 0
                                                                                    ? annualFreeMonthDiscount.toFixed(2) > 0 ? annualFreeMonthDiscount.toFixed(2) : '0.00'
                                                                                    : annualAbsoluteTotalSum.toFixed(2) > 0 ? annualAbsoluteTotalSum.toFixed(2) : '0.00'}`
                                                                            }
                                                                            {/* <div style={serviceStyling.summaryTitleFont}>{`First Payment`}</div> */}
                                                                        </div>
                                                                        : ''
                                                                }

                                                                {
                                                                    paymentFrequency === 'quarterly'
                                                                        ? <div style={{ fontWeight: 'bold' }} >
                                                                            {(discountSetupFee !== 0 && discountSetupFee > 0) && isDiscountSetupFeeVoided && !noSetUpFee
                                                                                ? freeMonthDiscount > 0
                                                                                    ? `${discountByPercentage > 0 ? `$${(freeMonthDiscount - discountByPercentage).toFixed(2) > 0 ? (freeMonthDiscount - discountByPercentage).toFixed(2) : '0.00'}` : `${freeMonthDiscount.toFixed(2)}`}`
                                                                                    : ''
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                        : ''
                                                                }

                                                                {
                                                                    paymentFrequency === 'monthly'
                                                                        ? <div style={{ fontWeight: 'bold' }} >
                                                                            {(discountSetupFee !== 0 && discountSetupFee > 0) && isDiscountSetupFeeVoided && !noSetUpFee
                                                                                ? freeMonthDiscount > 0
                                                                                    ? `${discountByPercentage > 0 ? `$${(freeMonthDiscount - discountByPercentage).toFixed(2) > 0 ? (freeMonthDiscount - discountByPercentage).toFixed(2) : '0.00'}` : `${freeMonthDiscount.toFixed(2)}`}`
                                                                                    : '$0'
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                        : ''
                                                                }

                                                                {
                                                                    paymentFrequency === 'annually'
                                                                        ? <div style={{ fontWeight: 'bold' }} >
                                                                            {(discountSetupFee !== 0 && discountSetupFee > 0) && isDiscountSetupFeeVoided && !noSetUpFee
                                                                                ? annualFreeMonthDiscount > 0
                                                                                    ? `${discountByPercentage > 0 ? `$${(annualFreeMonthDiscount - discountByPercentage).toFixed(2) > 0 ? (annualFreeMonthDiscount - discountByPercentage).toFixed(2) : '0.00'}` : `${annualFreeMonthDiscount.toFixed(2)}`}`
                                                                                    : ''
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: '2%' }}></div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                        : ''}
                                </div>
                                {/* harris summary 2 */}

                                <div>
                                    <div className="row">
                                        <div >
                                            <div className="col-1 hide_if_mobile_screen" style={serviceStyling.toggleButtonPositionServices} id='toggleToServices' onClick={() => this.toggleToServices()}>
                                                <div className={'fas fa-chevron-circle-right'} style={serviceStyling.toggleButtonToServices}></div>
                                            </div>
                                        </div>
                                        <div className="col-12 container" style={summaryPageSelected ? { display: 'none' } : { border: 'none' }}>
                                            <div>
                                                <div>
                                                    <div>
                                                        <br />

                                                        <div className="show_if_mobile_screen hide_if_not_mobile_screen">
                                                            <span style={{ fontSize: '20px' }}>
                                                                <i className="show_if_mobile_screen hide_if_not_mobile_screen fa-solid">
                                                                    <Icon icon="ic:sharp-account-circle" style={{ fontSize: '30px' }} />
                                                                </i>
                                                            </span>
                                                            <span style={serviceStyling.formTitleFont}>
                                                                {` Customer Sign up`}
                                                            </span>
                                                        </div>

                                                        <br />
                                                        <div className="text-center">

                                                            <div style={serviceStyling.customerFormHeader} className='container'>
                                                                <div className="row text-center">
                                                                    {/* customerFormHeaderPreviousStep */}
                                                                    <div className="col"
                                                                        // style={serviceStyling.testing}
                                                                        style={onStepOne
                                                                            ? onStepTwo ? serviceStyling.headerStep1 : serviceStyling.customerFormHeaderStep1
                                                                            : serviceStyling.customerFormHeaderStep1white
                                                                        }
                                                                    >
                                                                        Step 1
                                                                    </div>

                                                                    <div className="col"
                                                                        style={onStepTwo
                                                                            ? onStepThree ? serviceStyling.headerStep2 : serviceStyling.customerFormHeaderNextStep
                                                                            : serviceStyling.customerFormHeaderNextStepWhite
                                                                        }
                                                                    >
                                                                        Step 2
                                                                    </div>

                                                                    {/* !!!! Hidden due to billing page being hidden */}
                                                                    <div className="col"
                                                                        style={onStepThree
                                                                            ? serviceStyling.customerFormHeaderNextStep
                                                                            : serviceStyling.customerFormHeaderNextStepWhite
                                                                        }
                                                                    >
                                                                        Step 3
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div style={serviceStyling.centerContainer}>
                                                                <div style={serviceStyling.customerFormLayout}>
                                                                    <CustomerSignupForm
                                                                        hubspotContactId={this.state.hubspotContactId}
                                                                        hubspotDealId={this.state.hubspotDealId}
                                                                        hubSpotIdHandler={this.hubSpotIdHandler}
                                                                        selectedSalesRep={selectedSalesRep}
                                                                        selectedAreaServceData={selectedAreaServceData}

                                                                        annualRate={annualRate}
                                                                        monthlyRate={monthlyRate}

                                                                        toggleCustomerSignUpHandle={this.toggleCustomerSignUpHandle}
                                                                        onStepTwoHandle={this.onStepTwoHandle}
                                                                        onStepThreeHandle={this.onStepThreeHandle}

                                                                        typeOfServiceSelected={typeOfServiceSelected}
                                                                        totalSum={totalSum}
                                                                        monthlyGlassServiceSelected={monthlyGlassServiceSelected}
                                                                        glassMonthlyOnlyProvidedCost={glassMonthlyOnlyProvidedCost}

                                                                        address1Handle={this.address1Handle}
                                                                        address2Handle={this.address2Handle}
                                                                        cityHandle={this.cityHandle}
                                                                        stateNameHandle={this.stateNameHandle}
                                                                        zipCodeHandle={this.zipCodeHandle}
                                                                        firstNameHandle={this.firstNameHandle}
                                                                        lastNameHandle={this.lastNameHandle}
                                                                        emailHandle={this.emailHandle}
                                                                        emailConfirmationHandle={this.emailConfirmationHandle}
                                                                        phoneNumberHandle={this.phoneNumberHandle}
                                                                        specialAddressInstructionsHandle={this.specialAddressInstructionsHandle}
                                                                        salesRepNameHandle={this.salesRepNameHandle}
                                                                        specialNotesHandle={this.specialNotesHandle}
                                                                        reviewedAndConfirmHandle={this.reviewedAndConfirmHandle}
                                                                        interestedInLearningMoreHandle={this.interestedInLearningMoreHandle}
                                                                        interestedInBecomingADriverHandle={this.interestedInBecomingADriverHandle}

                                                                        address1={this.state.address1}
                                                                        address2={this.state.address2}
                                                                        city={this.state.city}
                                                                        stateName={this.state.stateName}
                                                                        zipcode={this.state.zipcode}
                                                                        firstName={this.state.firstName}
                                                                        lastName={this.state.lastName}
                                                                        email={this.state.email}
                                                                        emailConfirmation={this.state.emailConfirmation}
                                                                        phoneNumber={this.state.phoneNumber}
                                                                        specialAddressInstructions={this.state.specialAddressInstructions}
                                                                        salesRepName={this.state.salesRepName}
                                                                        specialNotes={this.state.specialNotes}
                                                                        reviewedAndConfirm={this.state.reviewedAndConfirm}
                                                                        interestedInLearningMore={this.state.interestedInLearningMore}
                                                                        interestedInBecomingADriver={this.state.interestedInBecomingADriver}

                                                                        areaOfService={this.state.areaOfService}
                                                                        pickUpSchedule={this.state.pickUpSchedule}
                                                                        glassServiceSelected={this.state.glassServiceSelected}
                                                                        noSetUpFee={this.state.noSetUpFee}
                                                                        oneMonthFree={this.state.oneMonthFree}
                                                                        discountSetupFee={this.state.discountSetupFee}
                                                                        paymentMethod={this.state.paymentMethod}
                                                                        paymentFrequency={this.state.paymentFrequency}
                                                                        isDiscountSetupFeeVoided={isDiscountSetupFeeVoided}
                                                                        pickupStartDate={pickupStartDate}
                                                                        customerSignupFormCompleted={customerSignupFormCompleted}
                                                                        serviceInformationFormCompleted={serviceInformationFormCompleted}
                                                                        setPickupStartDate={this.setPickupStartDate}

                                                                        serviceInformationFormHandle={this.serviceInformationFormHandle}
                                                                        customerSignupFormHandle={this.customerSignupFormHandle}
                                                                        customerSignupFormDisableHandle={this.customerSignupFormDisableHandle}
                                                                        discountHandle={this.discountHandle}
                                                                        selectSalesRepFeeHandle={this.selectSalesRepFeeHandle}
                                                                        areaOfServiceHandle={this.areaOfServiceHandle}
                                                                        pickUpScheduleHandle={this.pickUpScheduleHandle}
                                                                        glassServiceHandle={this.glassServiceHandle}
                                                                        noSetUpFeeHandle={this.noSetUpFeeHandle}
                                                                        oneMonthFreeHandle={this.oneMonthFreeHandle}
                                                                        discountSetupFeeHandle={this.discountSetupFeeHandle}
                                                                        paymentMethodHandle={this.paymentMethodHandle}
                                                                        paymentFrequencyHandle={this.paymentFrequencyHandle}

                                                                        annualTotalSum={annualTotalSum}
                                                                        basePrice={basePrice}
                                                                        annualBasePrice={annualBasePrice}
                                                                        glassPrice={glassPrice}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* harris */}
                            <div className="show_if_mobile_screen hide_if_not_mobile_screen col-1" >
                                <div style={{ border: '2px solid white' }}>
                                    <div className="row text-center">
                                        <div className="col-12" onClick={() => this.toggleToServices()} style={serviceStyling.iconStyling}
                                        >
                                            <i >
                                                <Icon icon="ant-design:info-circle-filled" style={{ fontSize: '40px' }} />
                                            </i>
                                        </div>
                                        <div className="col-12" style={{ paddingTop: '20%' }}>
                                        </div>
                                        <div className="col-12" onClick={() => this.toggleToSummary()} style={serviceStyling.iconStyling}>
                                            <i>
                                                <Icon icon={!summaryPageSelected ? "bi:clipboard2-data-fill" : 'ic:sharp-account-circle'} style={{ fontSize: '40px' }} />
                                            </i>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-2 hide_if_mobile_screen">
                                <div style={serviceStyling.columnRightAndLeftContainer}>
                                    <div className='container'>
                                        <br />
                                        <div className='text-center' style={serviceStyling.titleFont}>Summary</div>

                                        <div style={serviceStyling.totalSumSpacing}></div>

                                        <div>
                                            <div className='text-center' style={serviceStyling.titleFontForLargeTitle1}>Services:</div>

                                            <div className='text-center' style={serviceStyling.titleFont}>
                                                <div style={serviceStyling.summaryTitleFont}>
                                                    {typeOfServiceSelected === 'everyOtherWeek' ||
                                                        typeOfServiceSelected === 'weekly' ||
                                                        typeOfServiceSelected === 'monthly'
                                                        ? 'Standard Pickup' : ''}

                                                    {typeOfServiceSelected !== 'monthlyGlassOnly' &&
                                                        (typeOfServiceSelected === 'everyOtherWeek+Glass' ||
                                                            typeOfServiceSelected === 'weekly+Glass' ||
                                                            typeOfServiceSelected === 'monthly+Glass')
                                                        ? 'Standard Pickup + Glass'
                                                        : ''
                                                    }
                                                    {typeOfServiceSelected === 'monthlyGlassOnly' ? 'Glass Only' : ''}
                                                </div>
                                            </div>

                                            <div style={serviceStyling.spacingBetweenContent}></div>

                                            <div className='text-center' style={serviceStyling.monthlyCostStyle}>

                                                {paymentFrequency === 'quarterly'
                                                    ? <span className='text-center' style={serviceStyling.monthlyCost}>
                                                        {` $${glassMonthlyOnlyProvidedCost !== 0 ? (totalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : totalSum.toFixed(2)} / month`}
                                                    </span>
                                                    : ''
                                                }

                                                {paymentFrequency === 'monthly'
                                                    ? <span className='text-center' style={serviceStyling.monthlyCost}>
                                                        {` $${glassMonthlyOnlyProvidedCost !== 0 ? (totalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : totalSum.toFixed(2)} / month`}
                                                    </span>
                                                    : ''
                                                }

                                                {paymentFrequency === 'annually'
                                                    ? <span className='text-center' style={serviceStyling.monthlyCost}>
                                                        {` $${glassMonthlyOnlyProvidedCost !== 0 ? (annualTotalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : annualTotalSum.toFixed(2)} / month`}
                                                    </span>
                                                    : ''
                                                }
                                            </div>

                                            <div>
                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>

                                                    {paymentFrequency === 'quarterly'
                                                        ? <div>
                                                            <div>
                                                                {`$${absoluteTotalSum.toFixed(2)}`}
                                                                <div style={serviceStyling.SubsequentTitleFont}>{`Subsequent payments`}</div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>

                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>

                                                    {paymentFrequency === 'monthly'
                                                        ? <div>
                                                            <div>
                                                                {`$${absoluteTotalSum.toFixed(2)}`}
                                                                <div style={serviceStyling.SubsequentTitleFont}>{`Subsequent payments`}</div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>

                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>
                                                    {paymentFrequency === 'annually'
                                                        ? <div>
                                                            <div>
                                                                {`$${annualAbsoluteTotalSum.toFixed(2)}`}
                                                                <div style={serviceStyling.SubsequentTitleFont}>{`Subsequent payments`}</div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>

                                            </div>
                                        </div>


                                        <div style={serviceStyling.totalSumSpacing}></div>

                                        <div className='text-center' style={serviceStyling.titleFontForLargeTitle2}>{`Pickup Frequency: `}</div>

                                        <div className='text-center' style={serviceStyling.titleFont}>
                                            <div style={serviceStyling.summaryTitleFont}>
                                                {typeOfServiceSelected === 'everyOtherWeek' || typeOfServiceSelected === 'everyOtherWeek+Glass' ? 'Every other' : ''}
                                                {/* {typeOfServiceSelected === 'weekly' ? 'Weekly' || typeOfServiceSelected === 'weekly+Glass' : ''} */}
                                                {typeOfServiceSelected === 'weekly' || typeOfServiceSelected === 'weekly+Glass' ? `Weekly` : ''}
                                                {typeOfServiceSelected === 'monthly' || typeOfServiceSelected === 'monthly+Glass' || typeOfServiceSelected === 'monthlyGlassOnly' ? 'Monthly' : ''}
                                            </div>
                                        </div>

                                        <div>
                                            <div style={serviceStyling.totalSumSpacing}></div>

                                            <div className='text-center' style={serviceStyling.titleFontForLargeTitle}>
                                                {paymentFrequency === 'quarterly' || paymentFrequency === 'annually' || paymentFrequency === 'monthly' ? `Recurring Billing: ` : ''}
                                            </div>

                                            <div>
                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>
                                                    {paymentFrequency === 'quarterly'
                                                        ? <div>
                                                            <div style={serviceStyling.summaryTitleFont}>{`Quarterly`}</div>
                                                        </div>
                                                        : ''}
                                                </div>

                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>

                                                    {paymentFrequency === 'monthly'
                                                        ? <div>
                                                            <div style={serviceStyling.summaryTitleFont}>{`Monthly`}</div>
                                                        </div>
                                                        : ''}
                                                </div>

                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>
                                                    {paymentFrequency === 'annually'
                                                        ? <div>
                                                            <div style={serviceStyling.summaryTitleFont}>{`Annually`}</div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={serviceStyling.totalSumSpacing}></div>

                                            <div className='text-center' style={serviceStyling.titleFontForLargeTitle}>
                                                {noSetUpFee || oneMonthFree || isDiscountSetupFeeVoided
                                                    ? <div>
                                                        <div style={serviceStyling.discountTitleDivider}>s</div>
                                                        <div>{`Discount: `}</div>
                                                    </div>
                                                    : ''}
                                            </div>

                                            <div className='text-center' style={serviceStyling.setupFeeStyle}>
                                                <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>
                                                    {
                                                        paymentFrequency.length > 0
                                                            ? <div>
                                                                {noSetUpFee
                                                                    ? <div>
                                                                        <div>
                                                                            {`-$${basicSetupCost}`}
                                                                        </div>
                                                                        <div style={serviceStyling.summaryTitleFont}>
                                                                            {` setup fee`}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        paymentFrequency === 'quarterly'
                                                            ? <div>
                                                                {oneMonthFree
                                                                    ? <div>
                                                                        <div>
                                                                            {`-$${totalSum} `}
                                                                        </div>
                                                                        <div style={serviceStyling.summaryTitleFont}>
                                                                            {`one month free`}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        paymentFrequency === 'monthly'
                                                            ? <div>
                                                                {oneMonthFree
                                                                    ? <div>
                                                                        <div>
                                                                            {`-$${totalSum} `}
                                                                        </div>
                                                                        <div style={serviceStyling.summaryTitleFont}>
                                                                            {`one month free`}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        paymentFrequency === 'annually'
                                                            ? <div>
                                                                {oneMonthFree
                                                                    ? <div>
                                                                        <div>
                                                                            {`-$${annualTotalSum} `}
                                                                        </div>
                                                                        <div style={serviceStyling.summaryTitleFont}>
                                                                            {`one month free`}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        paymentFrequency.length > 0
                                                            ? <div>
                                                                {isDiscountSetupFeeVoided
                                                                    ? <div>
                                                                        <div>
                                                                            {`-${discountSetupFee * 100}% `}
                                                                        </div>
                                                                        <div style={serviceStyling.summaryTitleFont}>
                                                                            {`off setup fee`}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : ''
                                                    }

                                                </div>
                                            </div>

                                            <div className='text-center' style={serviceStyling.titleFontForLargeTitle}>
                                                {noSetUpFee || oneMonthFree || isDiscountSetupFeeVoided
                                                    ? <div>
                                                        <div style={serviceStyling.discountTitleDivider}>s</div>
                                                    </div>
                                                    : ''}
                                            </div>

                                        </div>

                                        <div>
                                            {noSetUpFee || oneMonthFree || isDiscountSetupFeeVoided
                                                ? <div style={serviceStyling.totalSumSpacing}></div>
                                                : ''}
                                        </div>

                                        <div className='text-center' style={serviceStyling.setupFeeStyle}>
                                            <div className='text-center' style={serviceStyling.paymentFrequencyCostStyle}>
                                                <div style={serviceStyling.titleFontForLargeTitle}>

                                                    {paymentFrequency.length > 0 ? 'Total Sum' : ''}</div>

                                                {
                                                    paymentFrequency === 'quarterly' && !isDiscountSetupFeeVoided
                                                        ? <div>
                                                            {noSetUpFee
                                                                ? `$${freeMonthDiscount > 0
                                                                    ? (freeMonthDiscount - basicSetupCost).toFixed(2) > 0 ? (freeMonthDiscount - basicSetupCost).toFixed(2) : '0.00'
                                                                    : (absoluteTotalSum - basicSetupCost).toFixed(2) > 0 ? (absoluteTotalSum - basicSetupCost).toFixed(2) : '0.00'}`
                                                                : `$${freeMonthDiscount > 0
                                                                    ? freeMonthDiscount.toFixed(2) > 0 ? freeMonthDiscount.toFixed(2) : '0.00'
                                                                    : absoluteTotalSum.toFixed(2) > 0 ? absoluteTotalSum.toFixed(2) : '0.00'}`
                                                            }
                                                            <div style={serviceStyling.summaryTitleFont}>{`First Payment`}</div>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    paymentFrequency === 'monthly' && !isDiscountSetupFeeVoided
                                                        ? <div>
                                                            {noSetUpFee
                                                                ? `$${freeMonthDiscount > 0
                                                                    ? (freeMonthDiscount - basicSetupCost).toFixed(2) > 0
                                                                        ? (freeMonthDiscount - basicSetupCost).toFixed(2)
                                                                        : '0.00'
                                                                    : Math.sign(basicSetupCost - (absoluteTotalSum - basicSetupCost)) === 0
                                                                        ? (absoluteTotalSum - basicSetupCost).toFixed(2)
                                                                        : '0.00'}`
                                                                : freeMonthDiscount > 0
                                                                    ? freeMonthDiscount.toFixed(2) > 0
                                                                        ? freeMonthDiscount.toFixed(2)
                                                                        : '0.00'
                                                                    : `$${basicSetupCost.toFixed(2) - basicSetupCost.toFixed(2)}`}
                                                            <div style={serviceStyling.summaryTitleFont}>{`First Payment`}</div>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    paymentFrequency === 'annually' && !isDiscountSetupFeeVoided
                                                        ? <div>
                                                            {noSetUpFee
                                                                ? `$${annualFreeMonthDiscount > 0
                                                                    ? (annualFreeMonthDiscount - basicSetupCost).toFixed(2) > 0 ? (annualFreeMonthDiscount - basicSetupCost).toFixed(2) : '0.00'
                                                                    : (annualAbsoluteTotalSum - basicSetupCost).toFixed(2) > 0 ? (annualAbsoluteTotalSum - basicSetupCost).toFixed(2) : '0.00'}`
                                                                : `$${annualFreeMonthDiscount > 0
                                                                    ? annualFreeMonthDiscount.toFixed(2) > 0 ? annualFreeMonthDiscount.toFixed(2) : '0.00'
                                                                    : annualAbsoluteTotalSum.toFixed(2) > 0 ? annualAbsoluteTotalSum.toFixed(2) : '0.00'}`
                                                            }
                                                            <div style={serviceStyling.summaryTitleFont}>{`First Payment`}</div>
                                                        </div>
                                                        : ''
                                                }

                                                {
                                                    paymentFrequency === 'quarterly'
                                                        ? <div>
                                                            {(discountSetupFee !== 0 && discountSetupFee > 0) && isDiscountSetupFeeVoided && !noSetUpFee
                                                                ? freeMonthDiscount > 0
                                                                    ? `${discountByPercentage > 0 ? `$${(freeMonthDiscount - discountByPercentage).toFixed(2) > 0 ? (freeMonthDiscount - discountByPercentage).toFixed(2) : '0.00'}` : `${freeMonthDiscount.toFixed(2)}`}`
                                                                    : ''
                                                                : ''
                                                            }
                                                        </div>
                                                        : ''
                                                }

                                                {
                                                    paymentFrequency === 'monthly'
                                                        ? <div>
                                                            {(discountSetupFee !== 0 && discountSetupFee > 0) && isDiscountSetupFeeVoided && !noSetUpFee
                                                                ? freeMonthDiscount > 0
                                                                    ? `${discountByPercentage > 0 ? `$${(freeMonthDiscount - discountByPercentage).toFixed(2) > 0 ? (freeMonthDiscount - discountByPercentage).toFixed(2) : '0.00'}` : `${freeMonthDiscount.toFixed(2)}`}`
                                                                    : '$0'
                                                                : ''
                                                            }
                                                        </div>
                                                        : ''
                                                }

                                                {
                                                    paymentFrequency === 'annually'
                                                        ? <div>
                                                            {(discountSetupFee !== 0 && discountSetupFee > 0) && isDiscountSetupFeeVoided && !noSetUpFee
                                                                ? annualFreeMonthDiscount > 0
                                                                    ? `${discountByPercentage > 0 ? `$${(annualFreeMonthDiscount - discountByPercentage).toFixed(2) > 0 ? (annualFreeMonthDiscount - discountByPercentage).toFixed(2) : '0.00'}` : `${annualFreeMonthDiscount.toFixed(2)}`}`
                                                                    : ''
                                                                : ''
                                                            }
                                                        </div>
                                                        : ''
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>

                        </div>
                        : <div>
                            <div className='row'>
                                <div className="col-12">
                                    <div style={serviceStyling.headerSpacing}></div>
                                </div>

                                <div className="col-lg-6 col-11">
                                    <div style={serviceStyling.columnRightAndLeftContainer}>
                                        <Element className="element" id="scroll-container" style={serviceStyling.scrollBarContainer}>
                                            <Element name="scroll-container-first-element" style={{
                                                // marginBottom: '100px'
                                            }}>
                                                <div >
                                                    {/* 1 pickup schedule boxes starts here */}
                                                    <div className='container'>
                                                        <br />
                                                        <div className="hide_if_mobile_screen" style={serviceStyling.titleFont}>
                                                            {` Information`}
                                                        </div>

                                                        <div className="show_if_mobile_screen hide_if_not_mobile_screen">
                                                            <span style={{ fontSize: '20px' }}>
                                                                <i className="show_if_mobile_screen hide_if_not_mobile_screen fa-solid">
                                                                    <Icon icon="ant-design:info-circle-filled" style={{ fontSize: '30px' }} />
                                                                </i>
                                                            </span>
                                                            <span style={serviceStyling.formTitleFont}>
                                                                {` Information`}
                                                            </span>
                                                        </div>

                                                        <div className="hide_if_not_mobile_screen" style={serviceStyling.errorMsg}>{errorMsg
                                                            ?
                                                            <div className="text-center">
                                                                <div style={serviceStyling.centerContainer}>
                                                                    {`Please Select Sales Rep`}
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }</div>
                                                        {basicPickupSchedules.everyOther ? <div>
                                                            <div style={serviceStyling.spacingBetweenContent}></div>
                                                        </div> : null}
                                                        <div style={basicPickupSchedules.everyOther ? serviceStyling.infoReportContainer : null}>
                                                            {basicPickupSchedules.everyOther
                                                                ? <div>
                                                                    <div className='text-center' style={serviceStyling.boxHeaders}>
                                                                        {`${basicPickupSchedules.everyOther ? `Every other week` : ''}`}
                                                                    </div>
                                                                    <div className="container">
                                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                                        <div className="row">
                                                                            <div className={!glassPickupSchedules.everyOther ? "col-12 text-center" : "col-6 text-center"}>
                                                                                <div>
                                                                                    Standard Pickups
                                                                                </div>
                                                                                <div className='text-center' style={serviceStyling.desciptionFont}>
                                                                                    <div className="mobile_font_size">
                                                                                        <div >{`Plastic, aluminium, paper &`}</div>
                                                                                        <div>{`cardboard recyling`}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                <div>
                                                                                    <div style={serviceStyling.monthlyCostStyle} className="mobile_price_font_size">{`${monthlyRate.everyOther !== 0 ? `$${monthlyRate.everyOther}/month` : ''}`}</div>
                                                                                    {/* annualTotalSum below */}
                                                                                    {/* <div className='text-center'>or</div>
                                                                                <div style={serviceStyling.monthlyCostStyle}>{`${annualRate.everyOther !== 0 ? `$${annualRate.everyOther / 12} / month` : ''}`}</div> */}
                                                                                </div>
                                                                                <div style={serviceStyling.spacingBetweenDiv}></div>

                                                                                {/* select button */}
                                                                                <div style={serviceStyling.centerContainer}>
                                                                                    {
                                                                                        typeOfServiceSelected === 'everyOtherWeek'
                                                                                            ? <div onClick={() => this.unSelectServiceHandle()} style={glassPickupSchedules.everyOther ? serviceStyling.selectedButton : serviceStyling.selectedButtonSizeNoGlass}>
                                                                                                Selected
                                                                                            </div>
                                                                                            : <div id='selectService' onClick={() => this.serviceSelectionHandle(monthlyRate.everyOther, 0, `everyOtherWeek`, annualRate.everyOther)} style={glassPickupSchedules.everyOther ? serviceStyling.selectButton : serviceStyling.selectButtonSizeNoGlass}>
                                                                                                Select
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {/* glass */}
                                                                            {!glassPickupSchedules.everyOther
                                                                                ? ''
                                                                                : <div className="col-6 text-center">
                                                                                    <div>
                                                                                        Standard + Glass
                                                                                    </div>
                                                                                    <div className='text-center' style={serviceStyling.desciptionFont}>
                                                                                        <div className="mobile_font_size">
                                                                                            <div>{`Plastic, aluminium, paper,`}</div>
                                                                                            <div>{`glass & cardboard recyling`}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>

                                                                                    <div>
                                                                                        <div style={serviceStyling.monthlyCostStyle}>
                                                                                            {`${glassPickupSchedules.everyOther
                                                                                                ? monthlyRate.everyOther !== 0
                                                                                                    ? `$${glassMonthlyRate.everyOther !== 0
                                                                                                        ? glassMonthlyRate.everyOther + monthlyRate.everyOther
                                                                                                        : ''}/month`
                                                                                                    : '' : 'Does not offer glass'
                                                                                                }`}</div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                    {/* select button */}
                                                                                    {typeOfServiceSelected === `everyOtherWeek+Glass`
                                                                                        ? <div onClick={() => this.unSelectServiceHandle()} style={serviceStyling.centerContainer}>
                                                                                            {glassPickupSchedules.everyOther
                                                                                                ? <div style={serviceStyling.selectedButton}>Selected</div>
                                                                                                : ''
                                                                                            }
                                                                                        </div>
                                                                                        : <div onClick={() => this.serviceSelectionHandle(monthlyRate.everyOther, glassMonthlyRate.everyOther, `everyOtherWeek+Glass`, annualRate.everyOther)} style={serviceStyling.centerContainer}>
                                                                                            {glassPickupSchedules.everyOther
                                                                                                ? <div style={serviceStyling.selectButton}>Select</div>
                                                                                                : ''
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {/* end of glass */}
                                                                        </div>
                                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                                    </div>
                                                                    <div style={serviceStyling.centerContainer}>
                                                                        <div style={serviceStyling.desciptionFont}>
                                                                            <div className="mobile_font_size">
                                                                                <div>Note:</div>
                                                                                <div>{basicPickupSchedules.everyOther ? `- Every other week pickups` : ''}</div>
                                                                                <div>- {`$${basicSetupCost} one time setup fee`}</div>
                                                                                <div>- Bags included every month</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''
                                                            }
                                                            {basicPickupSchedules.everyOther ? <div>
                                                                <div style={serviceStyling.spacingBetweenContent}></div>
                                                            </div> : null}
                                                        </div>
                                                    </div>
                                                    {/* 1 pickup schedule boxes ends here */}

                                                    {basicPickupSchedules.weekly ? <div>
                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                    </div> : null}

                                                    {/* 2 pickup schedule boxes starts here weekly */}
                                                    <div className='container'>
                                                        <div style={basicPickupSchedules.weekly ? serviceStyling.infoReportContainer : null}>
                                                            {basicPickupSchedules.weekly
                                                                ? <div>
                                                                    <div className='text-center' style={serviceStyling.boxHeaders}>

                                                                        {`${basicPickupSchedules.weekly ? `Weekly` : ''}`}
                                                                    </div>
                                                                    <div className="container">
                                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                                        <div className="row">
                                                                            <div className={!glassPickupSchedules.weekly ? "col-12 text-center" : "col-6 text-center"}>
                                                                                <div>
                                                                                    Standard Pickups
                                                                                </div>
                                                                                <div className='text-center' style={serviceStyling.desciptionFont}>
                                                                                    <div className="mobile_font_size">
                                                                                        <div>{`Plastic, aluminium, paper &`}</div>
                                                                                        <div>{`cardboard recyling`}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                <div>
                                                                                    <div style={serviceStyling.monthlyCostStyle}>{`${monthlyRate.weekly !== 0 ? `$${monthlyRate.weekly}/month` : ''}`}</div>
                                                                                </div>
                                                                                <div style={serviceStyling.spacingBetweenDiv}></div>

                                                                                {/* select button */}
                                                                                <div style={serviceStyling.centerContainer}>
                                                                                    {typeOfServiceSelected === `weekly`
                                                                                        ? <div onClick={() => this.unSelectServiceHandle()} style={glassPickupSchedules.weekly ? serviceStyling.selectedButton : serviceStyling.selectedButtonSizeNoGlass}>Selected</div>
                                                                                        : <div onClick={() => this.serviceSelectionHandle(monthlyRate.weekly, 0, `weekly`, annualRate.weekly)} style={glassPickupSchedules.weekly ? serviceStyling.selectButton : serviceStyling.selectButtonSizeNoGlass}>Select</div>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {/* Glass */}

                                                                            {/* glassMonthlyRate */}

                                                                            {!glassPickupSchedules.weekly
                                                                                ? ''
                                                                                : <div className="col-6 text-center">
                                                                                    <div>
                                                                                        Standard + Glass
                                                                                    </div>
                                                                                    <div className='text-center' style={serviceStyling.desciptionFont}>
                                                                                        <div className="mobile_font_size">
                                                                                            <div>{`Plastic, aluminium, paper,`}</div>
                                                                                            <div>{`glass & cardboard recyling`}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>

                                                                                    <div>
                                                                                        {/* double check this later. Currently comparing monthly glass and every other. they're not the same? */}
                                                                                        {/* every other */}
                                                                                        <div style={serviceStyling.monthlyCostStyle}>
                                                                                            {`${glassPickupSchedules.weekly
                                                                                                ? monthlyRate.weekly !== 0
                                                                                                    ? `$${glassMonthlyRate.weekly !== 0
                                                                                                        ? glassMonthlyRate.weekly + monthlyRate.weekly
                                                                                                        : ''}/month`
                                                                                                    : '' : 'Does not offer glass'
                                                                                                }`}</div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                    {/* select button */}
                                                                                    <div style={serviceStyling.centerContainer}>
                                                                                        {glassPickupSchedules.weekly
                                                                                            ? <div style={typeOfServiceSelected === 'weekly+Glass' ? serviceStyling.selectedButton : serviceStyling.selectButton}>
                                                                                                {typeOfServiceSelected === 'weekly+Glass'
                                                                                                    ? <div onClick={() => this.unSelectServiceHandle()}>Selected</div>
                                                                                                    : <div onClick={() => this.serviceSelectionHandle(monthlyRate.weekly, glassMonthlyRate.weekly, `weekly+Glass`, annualRate.weekly)}>Select</div>
                                                                                                }

                                                                                            </div>
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {/* end of glass */}
                                                                        </div>
                                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                                    </div>
                                                                    <div style={serviceStyling.centerContainer}>
                                                                        <div style={serviceStyling.desciptionFont}>
                                                                            <div className="mobile_font_size">
                                                                                <div>Note:</div>
                                                                                <div>{basicPickupSchedules.weekly ? `- Every week pickups` : ''}</div>
                                                                                <div>- {`$${basicSetupCost} one time setup fee`}</div>
                                                                                <div>- Bags included every month</div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                : ''
                                                            }
                                                            {basicPickupSchedules.weekly ? <div>
                                                                <div style={serviceStyling.spacingBetweenContent}></div>
                                                            </div> : null}
                                                        </div>
                                                    </div>
                                                    {/* end of 2 pickup schedule boxes starts here weekly */}

                                                    {basicPickupSchedules.weekly ? <div>
                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                    </div> : null}

                                                    {glassMonthlyRate.monthly && !basicPickupSchedules.weekly ? <div>
                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                    </div> : null}

                                                    {/* 3 pickup schedule boxes starts here monthly */}
                                                    <div className='container'>
                                                        <div style={basicPickupSchedules.monthly || glassPickupSchedules.monthly ? serviceStyling.infoReportContainer : null}>
                                                            {basicPickupSchedules.monthly || glassPickupSchedules.monthly
                                                                ? <div>
                                                                    <div className='text-center' style={serviceStyling.boxHeaders}>
                                                                        {`${basicPickupSchedules.monthly || glassPickupSchedules.monthly ? `Monthly` : ''}`}
                                                                    </div>
                                                                    <div className="container">
                                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                                        <div className="row">
                                                                            {!basicPickupSchedules.monthly
                                                                                ? ''
                                                                                : <div className={!glassPickupSchedules.monthly ? "col-12 text-center" : "col-6 text-center"}>
                                                                                    <div>
                                                                                        Standard Pickups
                                                                                    </div>
                                                                                    <div className='text-center' style={serviceStyling.desciptionFont}>
                                                                                        <div className="mobile_font_size">
                                                                                            <div>{`Plastic, aluminium, paper &`}</div>
                                                                                            <div>{`cardboard recyling`}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                    <div>
                                                                                        <div style={serviceStyling.monthlyCostStyle}>{`${monthlyRate.monthly !== 0 ? `$${monthlyRate.monthly}/month` : ''}`}</div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>

                                                                                    {/* select button */}
                                                                                    <div style={serviceStyling.centerContainer}>
                                                                                        {typeOfServiceSelected === 'monthly'
                                                                                            ? <div onClick={() => this.unSelectServiceHandle()} style={typeOfServiceSelected === 'monthly' ? serviceStyling.selectedButton : serviceStyling.selectButton}>Selected</div>
                                                                                            : <div onClick={() => this.serviceSelectionHandle(monthlyRate.monthly, 0, `monthly`, annualRate.monthly)} style={serviceStyling.selectButton}>Select</div>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {/* glass */}
                                                                            {!glassPickupSchedules.monthly
                                                                                ? ''
                                                                                : <div className={!basicPickupSchedules.monthly ? "col-12 text-center" : "col-6 text-center"}>
                                                                                    <div>
                                                                                        {!basicPickupSchedules.monthly ? `Standard Pickups` : `Standard + Glass`}
                                                                                    </div>
                                                                                    <div className='text-center' style={serviceStyling.desciptionFont}>
                                                                                        <div>
                                                                                            {glassPickupSchedules.monthly
                                                                                                ? <div className="mobile_font_size" >{`Glass`}</div>
                                                                                                : <div className="mobile_font_size" >
                                                                                                    <div>{`Plastic, aluminium, paper,`}</div>
                                                                                                    <div>{`glass & cardboard recyling`}</div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                    <div>
                                                                                        <div style={serviceStyling.monthlyCostStyle}>
                                                                                            {`${glassPickupSchedules.monthly || glassMonthlyRate.monthly
                                                                                                ? monthlyRate.monthly !== 0
                                                                                                    ? `$${glassMonthlyRate.monthly !== 0
                                                                                                        ? glassMonthlyRate.monthly + monthlyRate.monthly
                                                                                                        : ''}/month`
                                                                                                    : `$${glassMonthlyRate.monthly}/month` : ''
                                                                                                }`}</div>
                                                                                    </div>
                                                                                    <div style={serviceStyling.spacingBetweenDiv}></div>
                                                                                    <div style={serviceStyling.centerContainer}>
                                                                                        {glassPickupSchedules.monthly
                                                                                            ? <div
                                                                                                style={typeOfServiceSelected === 'monthlyGlassOnly' || typeOfServiceSelected === 'monthly+Glass' ? serviceStyling.selectedButtonMonthly : serviceStyling.selectButtonMonthly}
                                                                                            >
                                                                                                <div>
                                                                                                    {typeOfServiceSelected === 'monthlyGlassOnly' || typeOfServiceSelected === 'monthly+Glass'
                                                                                                        ? <div
                                                                                                            onClick={!basicPickupSchedules.monthly && monthlyRate.monthly === 0 && glassPickupSchedules.monthly || glassMonthlyRate.monthly
                                                                                                                ? () => this.unSelectServiceHandle() :
                                                                                                                () => this.unSelectServiceHandle()
                                                                                                            }
                                                                                                        >
                                                                                                            Selected
                                                                                                        </div>
                                                                                                        : <div
                                                                                                            onClick={!basicPickupSchedules.monthly && monthlyRate.monthly === 0 && glassPickupSchedules.monthly || glassMonthlyRate.monthly
                                                                                                                ? () => this.serviceSelectionHandle(glassMonthlyRate.monthly, monthlyRate.monthly, `monthlyGlassOnly`, annualRate.monthly) :
                                                                                                                () => this.serviceSelectionHandle(glassMonthlyRate.monthly, monthlyRate.monthly, `monthly+Glass`, annualRate.monthly)
                                                                                                            }
                                                                                                        >
                                                                                                            Select
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                                    </div>
                                                                    <div style={serviceStyling.centerContainer}>
                                                                        <div style={serviceStyling.desciptionFont}>
                                                                            {!basicPickupSchedules.monthly
                                                                                ? ' '
                                                                                : <div className="mobile_font_size">
                                                                                    <div>Note:</div>
                                                                                    <div>{basicPickupSchedules.monthly ? `- Monthly pickups` : ''}</div>
                                                                                    <div>- {`$${basicSetupCost} one time setup fee`}</div>
                                                                                    <div>- Bags included every month</div>
                                                                                </div>
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                : ''
                                                            }
                                                            {basicPickupSchedules.monthly || glassPickupSchedules.monthly ? <div>
                                                                <div style={serviceStyling.spacingBetweenContent}></div>
                                                            </div> : null}
                                                        </div>
                                                    </div>
                                                    {/* end of 3 pickup schedule boxes starts here monthly */}
                                                    {basicPickupSchedules.monthly || glassPickupSchedules.monthly ? <div>
                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                    </div> : null}

                                                    {/* glass */}

                                                    {glassMonthlyRate.monthly ? <div>
                                                        <div style={serviceStyling.spacingBetweenContent}></div>
                                                    </div> : null}
                                                </div>
                                            </Element>
                                        </Element>
                                    </div>
                                </div>

                                {/* harris */}
                                <div className="show_if_mobile_screen hide_if_not_mobile_screen col-1">
                                    <div className="row text-center">
                                        <div className="col-12 text-center" onClick={() => this.toggleCustomerSignUpHandle()} style={serviceStyling.iconStyling}>
                                            <i >
                                                <Icon icon="ic:sharp-account-circle" style={{ fontSize: '40px' }} />
                                            </i>
                                        </div>
                                        <div className="col-12" style={{ paddingTop: '20%' }}>
                                        </div>
                                        {toggleCustomerSignUp
                                            ? <div className="col-12" onClick={() => this.toggleToSummary()} style={serviceStyling.iconStyling}>
                                                <i >
                                                    <Icon icon="bi:clipboard2-data-fill" style={{ fontSize: '40px' }} />
                                                </i>
                                            </div> : ''}
                                    </div>
                                </div>

                                <div className="col-4 hide_if_mobile_screen">
                                    <div style={serviceStyling.columnRightAndLeftContainer}>

                                        <div className="container" >
                                            <div className="row">
                                                <div className="col-1 " style={serviceStyling.toggleButtonPositionCustomerSignUp}>
                                                    <div id='toggleCustomerSignUpHandle' onClick={() => this.toggleCustomerSignUpHandle()} className={'fas fa-chevron-circle-left'} style={serviceStyling.toggleButtonToCustomerSignUp}>
                                                    </div>
                                                </div>
                                                <div className="col-11 container">
                                                    <br />
                                                    <div style={serviceStyling.titleFont}>Customer Sign ups</div>
                                                    <br />
                                                    <div className="text-center">
                                                        <div style={serviceStyling.centerContainer}>
                                                            <div style={!toggleCustomerSignUp ? serviceStyling.stepsButtonGreen : serviceStyling.stepsButton}>Step 1</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="text-center">
                                                        <div style={serviceStyling.centerContainer}>
                                                            <div style={serviceStyling.stepsButton}>Step 2</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div style={serviceStyling.errorMsg}>{errorMsg
                                                        ?
                                                        <div className="text-center">
                                                            <div style={serviceStyling.centerContainer}>
                                                                {`Please Select Sales Rep`}
                                                            </div>
                                                        </div>
                                                        : ''
                                                    }</div>
                                                    {/* // !!!! Hidden due to billing page being hidden */}
                                                    <div className="text-center">
                                                        <div style={serviceStyling.centerContainer}>
                                                            <div style={serviceStyling.stepsButton}>Step 3</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2 hide_if_mobile_screen">
                                    <div style={serviceStyling.columnRightAndLeftContainer}>
                                        <div className='container'>
                                            <br />
                                            <div className='text-center' style={serviceStyling.titleFont}>Total Sums</div>

                                            <div style={serviceStyling.totalSumSpacing}></div>

                                            <div className='text-center' style={serviceStyling.monthlyCostStyle}>
                                                {`$${glassMonthlyOnlyProvidedCost !== 0 ? (totalSum + glassMonthlyOnlyProvidedCost).toFixed(2) : totalSum.toFixed(2)}`}
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div style={serviceStyling.extraPaddingBottom}></div>

            </div >
        );
    }
}

export default Services
