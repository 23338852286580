/* eslint-disable */
import React, { Component } from "react";
import { Modal } from 'react-bootstrap'
import modalstyling from './modalStyling'

class LocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { listoflocations, locationselected } = this.props
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='text-center' style={modalstyling.titleStyle}>Select your location</div>
                    {listoflocations.length > 0
                        ? <div>
                            <div className="container text-center">
                                <div className="row" style={modalstyling.centerContainer}>

                                    {listoflocations.map((location, i) => {
                                        return (
                                            <div key={i} className="col-md-3">
                                                <br />
                                                <div onClick={() => locationselected(location)} style={modalstyling.cardBoxLayout}>
                                                    <div onClick={this.props.onHide}>
                                                        {location.locationDisplayName}
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        : null}
                </Modal.Body>
            </Modal>
        );
    }
}


export default LocationModal

