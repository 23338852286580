/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux'
import locationsDisplayStyle from './LocationsDisplayStyle'
import globalStyling from '../../../globalStyling'
import CountyModal from '../modal/countyModal'
import LocationModal from '../modal/locationModal'
import Services from '../../services/services'
import '../../../index.css'
import QRPassCode from '../../QRCode/passcode'

class LocationsDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: 'john doe',

            locationProfileApi: [],

            isStateSelected: 100,
            locationExist: false,

            stateDisplayName: '',
            countyDisplayName: '',
            locationDisplayName: '',

            locationProfiles: [],

            listOfStates: [],

            listOfCounties: [],
            countiesArr: [],

            listOfLocations: [],
            locationsArr: [],

            selectedAreaData: [],

            showCountyModal: false,
            showLocationModal: false,

            locationSelectionCompleted: false,

            qrGeneratorClicked: false
        };
    }

    async componentDidMount() {
        const { locationProfileApi, currentUser } = this.props

        let locationProfileACall = await locationProfileApi

        let locationProfiles = locationProfileACall.locationProfiles
        let listOfStates = []

        if (locationProfiles) {
            for (let i = 0; i < locationProfiles.length; i++) {
                if (locationProfiles[i].locationStatus !== 'INACTIVE') {
                    listOfStates.push({
                        stateName: locationProfiles[i].stateName,
                        stateDisplayName: locationProfiles[i].stateDisplayName
                    })
                }
            }
            // remove duplicates to show states
            let listOfStatesArr = listOfStates.filter((v, i, a) => a.findIndex(t => (t.stateName === v.stateName && t.stateDisplayName === v.stateDisplayName)) === i)

            this.setState({
                locationProfiles: [...locationProfiles],
                listOfStates: listOfStatesArr
            })
        }

    }

    qrGeneratorHandle = () => {
        this.setState({
            qrGeneratorClicked: !this.state.qrGeneratorClicked
        })
    }

    stateSelected = (state, indexSelected) => {
        const { locationProfiles, listOfStates, countiesArr, stateDisplayName } = this.state
        // redux
        const { selectedState, selectedCounty, selectedLocation } = this.props

        // if (state.stateDisplayName !== stateDisplayName) {
        this.setState({
            stateDisplayName: state.stateDisplayName,
            countyDisplayName: '',
            locationDisplayName: '',

            listOfCounties: [],
            countiesArr: [],

            listOfLocations: [],
            locationsArr: [],

            selectedAreaData: [],

            locationSelectionCompleted: false
        })
        // }

        let countiesArrArr = []
        let listOfCountiesArr = []

        for (let i = 0; i < locationProfiles.length; i++) {

            listOfCountiesArr.push({
                stateName: locationProfiles[i].stateName,
                stateDisplayName: locationProfiles[i].stateDisplayName,
                countyName: locationProfiles[i].countyName,
                countyDisplayName: locationProfiles[i].countyDisplayName
            })

            if (locationProfiles[i].stateName === state.stateName) {
                countiesArrArr.push(locationProfiles[i])
            }
        }
        // remove duplicates to show states
        let listOfStatesNew = listOfCountiesArr.filter((v, i, a) => a.findIndex(t => (t.countyName === v.countyName && t.stateDisplayName === v.stateDisplayName)) === i)
        // alphabetical order by county
        let listOfStatesSorted = listOfStatesNew.sort((a, b) => a.countyName > b.countyName ? 1 : b.countyName > a.countyName ? -1 : 0)
        // redux
        selectedState({
            stateDisplayName: state.stateDisplayName,
            stateName: state.stateName,
            countiesArr: countiesArrArr,
        })
        // end redux

        this.setState({
            stateDisplayName: state.stateDisplayName,
            countiesArr: countiesArrArr,
            listOfCounties: listOfStatesSorted,
            isStateSelected: indexSelected,
            showCountyModal: !this.state.showCountyModal

        })
    }

    countySelected = (county) => {
        const { countyDisplayName } = this.state
        const { selectedCounty } = this.props

        // if (county.countyDisplayName !== countyDisplayName) {
        this.setState({
            countyDisplayName: county.countyDisplayName,
            locationDisplayName: '',

            listOfLocations: [],
            locationsArr: [],

            selectedAreaData: [],

            locationSelectionCompleted: false
        })
        // }

        let { countiesArr } = this.state
        let listOfLocationsArr = []
        let locationsArr = []
        let selectedAreaData = []

        for (let i = 0; i < countiesArr.length; i++) {
            if (county.countyName === countiesArr[i].countyName) {
                if (countiesArr[i].doesLocationExist === true) {

                    listOfLocationsArr.push({
                        stateName: countiesArr[i].stateName,
                        stateDisplayName: countiesArr[i].stateDisplayName,
                        countyName: countiesArr[i].countyName,
                        countyDisplayName: countiesArr[i].countyDisplayName,

                        locationName: countiesArr[i].locationName,
                        locationDisplayName: countiesArr[i].locationDisplayName,
                        doesLocationExist: countiesArr[i].doesLocationExist
                    })

                    locationsArr.push(countiesArr[i])

                    this.setState({
                        showLocationModal: !this.state.showLocationModal
                    })
                } else {

                    selectedAreaData.push(countiesArr[i])

                    this.setState({
                        selectedAreaData: selectedAreaData,
                        locationSelectionCompleted: !this.state.locationSelectionCompleted
                    })

                }
            }
        }
        // alphabetical order by county
        let listOfLocationsSorted = listOfLocationsArr.sort((a, b) => a.locationName > b.locationName ? 1 : b.locationName > a.locationName ? -1 : 0)

        selectedCounty({
            countyDisplayName: county.countyDisplayName,
            countyName: county.countyName,
            locationsArr: locationsArr,
            selectedAreaData: selectedAreaData
        })

        this.setState({
            listOfLocations: listOfLocationsSorted,
            countyDisplayName: county.countyDisplayName,
            locationsArr: locationsArr
        })
    }

    locationSelected = (location) => {
        const { locationDisplayName } = this.state
        const { selectedLocation } = this.props

        if (location.locationDisplayName !== locationDisplayName) {
            //redux
            selectedLocation({
                selectedAreaData: [],
                locationDisplayName: location.locationDisplayName,
                locationName: location.locationName
            })
            // end of redux
            this.setState({
                locationDisplayName: location.locationDisplayName,
                selectedAreaData: []
            })
        }

        const { locationsArr } = this.state

        let selectedAreaData = []

        for (let i = 0; i < locationsArr.length; i++) {
            if (locationsArr[i].locationName === location.locationName) {
                selectedAreaData.push(locationsArr[i])

                //redux
                selectedLocation({
                    selectedAreaData: selectedAreaData,
                    locationDisplayName: locationsArr[i].locationDisplayName,
                    locationName: locationsArr[i].locationName,
                })
                // end of redux

                this.setState({
                    selectedAreaData: selectedAreaData,
                    locationDisplayName: locationsArr[i].locationDisplayName,
                    locationSelectionCompleted: !this.state.locationSelectionCompleted
                })
            }

        }
    }

    locationSelectionCompletedHandle = () => {
        this.setState({
            locationSelectionCompleted: !this.state.locationSelectionCompleted
        })
    }

    showCountyModalHandle = (result) => {
        this.setState({
            showCountyModal: result
        })
    }

    showLocationModalHandle = (result) => {
        this.setState({
            showLocationModal: result
        })
    }

    render() {
        const { saleReps } = this.props
        const {
            showLocationModal,
            showCountyModal,
            isStateSelected,
            stateDisplayName,
            listOfStates,
            listOfCounties,
            listOfLocations,
            countyDisplayName,
            locationDisplayName,
            selectedAreaData,
            userName,
            locationSelectionCompleted
        } = this.state

        let selectedAreaDataChange = []

        for (let i = 0; i < selectedAreaData.length; i++) {
            selectedAreaDataChange.push(selectedAreaData[i])
        }

        return (
            <div style={locationsDisplayStyle.serviceContainer}>
                {!locationSelectionCompleted
                    ? <div >
                        <div>
                            {listOfStates
                                ?
                                <div>
                                    {this.state.qrGeneratorClicked
                                        ?
                                        <div className="center-content">
                                            <QRPassCode qrGeneratorHandle={this.qrGeneratorHandle} />
                                        </div>
                                        : <div>

                                            <div className="hide_if_mobile_screen" style={{ paddingTop: '7%' }}></div>

                                            <div className="col-12 hide_if_mobile_screen " id='locationSelection' style={globalStyling.recyclopsFont} >Select your location</div>

                                            <div className="col-12 text-center select_your_location_display hide_if_not_mobile_screen" id='locationSelection' style={globalStyling.recyclopsFont} >Select your location</div>

                                            <div className="row" style={locationsDisplayStyle.centerContainer}>
                                                {listOfStates.map((state, i) => {
                                                    return (
                                                        <div key={i} className="col-2 text-center" style={isStateSelected === i ? locationsDisplayStyle.selectedCardBoxLayout : locationsDisplayStyle.cardBoxLayout}>
                                                            <div onClick={() => this.stateSelected(state, i)}>{state.stateDisplayName}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div className="center-content" style={{ paddingTop: '4%' }}>
                                                <button className="generate-qr-btn" onClick={this.qrGeneratorHandle}>{` Generate QR Code `}</button>
                                            </div>

                                        </div>
                                    }
                                </div>

                                : 'loading...'}
                        </div>

                        <br />

                        <CountyModal
                            show={showCountyModal}
                            onHide={() => this.showCountyModalHandle(false)}
                            listofcounties={listOfCounties}
                            statedisplayname={stateDisplayName}
                            countyselected={this.countySelected}
                        />

                        <br />

                        <LocationModal
                            show={showLocationModal}
                            onHide={() => this.showLocationModalHandle(false)}
                            listoflocations={listOfLocations}
                            locationselected={this.locationSelected}
                        />

                    </div>

                    : <div >
                        {selectedAreaData.length > 0
                            ? <Services
                                saleReps={saleReps}
                                signOut={this.props.signOut}
                                selectedAreaServceData={selectedAreaDataChange}
                                userName={userName}
                                locationSelectionCompletedHandle={this.locationSelectionCompletedHandle}
                            />
                            : null
                        }</div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // currentUser: state.currentUser,
        // locationProfileApi: state.locationProfileApi,
        // locationApi: state.locationApi
    };
};

const mapDispachToProps = (dispatch) => {
    return {
        selectedState: (selectedState) =>
            dispatch({ type: "STATE_SELECTED", selectedState: selectedState }),
        selectedCounty: (selectedCounty) =>
            dispatch({ type: "COUNTY_SELECTED", selectedCounty: selectedCounty }),
        selectedLocation: (selectedLocation) =>
            dispatch({ type: "LOCATION_SELECTED", selectedLocation: selectedLocation }),
    };
};
export default connect(mapStateToProps, mapDispachToProps)(LocationsDisplay);
