/* eslint-disable */
import React, { Component } from "react";
import customerSignupFormStyling from './customerSignupFormStyling'
import BillingForm from '../billing/billingForm'
import { updateCustomerZap } from '../helpers/zapier/updateCustomerZap'
import { selectedAreaServceDataFunc } from '../helpers/selected-area-servce-data/selectedAreaServceData'
import TimeStamp from '../helpers/timeStamp/timeStamp'
import { billingSetupIntent, signupStepTwo } from '../../api'
import DatePicker from "react-datepicker";

// import required css from library
import "react-datepicker/dist/react-datepicker.css";

class ServiceInformationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canMoveToNextPage: false,
            loading: false,
            errorMsg: false,
            stateAbrv: '',

            billingIntent: false,
            startPickupDate: '',
            pickupFrequency: '',

            billingStreetAddress1: '',
            billingStreetAddress2: '',
            billingCityName: '',
            billingStateName: '',
            billingZipCode: '',

            sameAsBillingAddress: true,
            stripeCustomerId: '',

        };
    }
    componentDidMount = () => {
        const { selectedAreaServceData } = this.props

        for (let i = 0; i < selectedAreaServceData.length; i++) {
            this.setState({
                stateAbrv: selectedAreaServceData[i].stateName
            })
        }

    }

    sameAsBillingAddressHandle = () => {
        this.setState({
            sameAsBillingAddress: !this.state.sameAsBillingAddress
        })
    }
    address1BillingHandle = (e) => {
        this.setState({
            billingStreetAddress1: e.target.value
        })
    }
    address2BillingHandle = (e) => {
        this.setState({
            billingStreetAddress2: e.target.value
        })
    }
    cityBillingHandle = (e) => {
        this.setState({
            billingCityName: e.target.value
        })
    }
    stateNameBillingHandle = (e) => {
        this.setState({
            billingStateName: e.target.value
        })
    }
    zipCodeBillingHandle = (e) => {
        this.setState({
            billingZipCode: e.target.value
        })
    }

    // !!!! Hidden due to billing page being hidden
    nextPageHandle = () => {
        const { onStepThreeHandle } = this.props

        this.updateCustomerZap()
        onStepThreeHandle()

        this.setState({
            canMoveToNextPage: !this.state.canMoveToNextPage
        })
    }


    signupStepTwoHandle = (result) => {

        const {
            selectedSalesRep, specialNotes, interestedInBecomingADriver, specialInstructions, specialAddressInstructions,
            selectedAreaServceData, email, firstName, lastName, customerType, phoneNumber, glassServiceSelected, timeStamp,
            address1, address2, city, stateName, zipcode, basePrice, glassPrice, typeOfServiceSelected, totalSum, monthlyGlassServiceSelected, glassMonthlyOnlyProvidedCost,
            areaOfService, pickUpSchedule, noSetUpFee, oneMonthFree, discountSetupFee, paymentMethod, paymentFrequency,
            serviceInformationFormHandle,
            hubspotDealId,
            hubspotContactId
        } = this.props

        const {
            sameAsBillingAddress,
            startPickupDate,
            billingStreetAddress1,
            billingStreetAddress2,
            billingCityName,
            billingStateName,
            billingZipCode,
            stripeCustomerId
        } = this.state

        let typeOfServiceVal = ''
        if (typeOfServiceSelected === 'everyOtherWeek+Glass' || typeOfServiceSelected === 'everyOtherWeek') {
            typeOfServiceVal = 'every_other'
        }
        if (typeOfServiceSelected === 'weekly+Glass' || typeOfServiceSelected === 'weekly') {
            typeOfServiceVal = 'weekly'
        }
        if (typeOfServiceSelected === 'monthly+Glass' || typeOfServiceSelected === 'monthly') {
            typeOfServiceVal = 'monthly'
        }

        let ifGlassServiceExist = ''
        if (typeOfServiceSelected.includes("+")) {
            ifGlassServiceExist = typeOfServiceSelected.split('+')
            if (ifGlassServiceExist.includes("Glass")) {
                ifGlassServiceExist = 'glass'
            }
        }

        let servicedAreaData = selectedAreaServceDataFunc(selectedAreaServceData)

        let discountSetupFeeStr = ''

        if (discountSetupFee) {
            discountSetupFeeStr = discountSetupFee.toString()
            discountSetupFeeStr = discountSetupFeeStr.substring(1);
        }


        let apiBody = {
            hubspotDealId: hubspotDealId,
            hubspotContactId: hubspotContactId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phoneNumber,
            customerType: "residential", // can only be one of the following: residential, commercial, government, enterprise
            serviceAddress: {
                line1: address1,
                line2: address2, //optional
                city: city,
                state: stateName, // must be two letter state abbreviation
                postalCode: zipcode
            },
            billingAddress: {
                line1: sameAsBillingAddress ? address1 : billingStreetAddress1,
                line2: sameAsBillingAddress ? address2 : billingStreetAddress2, // not required
                city: sameAsBillingAddress ? city : billingCityName,
                state: sameAsBillingAddress ? stateName : billingStateName,
                postalCode: sameAsBillingAddress ? zipcode : billingZipCode
            },
            operationDisplayName: servicedAreaData.operationDisplayName,
            stripeCustomerId: stripeCustomerId,
            stripePaymentMethodId: result?.setupIntent?.payment_method || 'card',
            door2door: true,
            salesRepName: selectedSalesRep,
            interestedInBecomingADriver: interestedInBecomingADriver,
            specialNotes: specialNotes,
            specialPickupInstructions: specialAddressInstructions,
            firstPickupDate: '',
            noSetupFee: noSetUpFee,
            oneMonthFree: oneMonthFree,
            setupFeeDiscount: discountSetupFeeStr, // can only be one of the following: .1, .2, .3, .4, .5
            pickupFrequency: typeOfServiceVal, // can only be one of the following: every_other, weekly, monthly, on_demand, first_week, second_week, third_week, fourth_week
            servicesSelected: ["mixed_recycling", ifGlassServiceExist], // can only contain the following: mixed, glass
            paymentFrequency: paymentFrequency, // can only be one of the following: monthly, quarterly, semiannually, annually
        }

        setTimeout(() => {
            signupStepTwo(apiBody, res => {
                window.scrollTo(0, 500);
                if (res.success) {
                    serviceInformationFormHandle()
                } else {
                    console.log(res, '<---ERROR');
                    alert('Something went wrong');
                }
            });
        }, 5000)
    }

    submitHandle = () => {
        const { selectedSalesRep, paymentFrequency, serviceInformationFormHandle } = this.props
        const { loading } = this.state

        if (!loading) {
            if (selectedSalesRep && paymentFrequency.length > 0) {
                this.setState({
                    loading: !this.state.loading,
                    errorMsg: false
                })

                let apiBody = this.updateCustomerZap()

                billingSetupIntent(apiBody, res => {
                    if (res?.stripeCustomerId) {
                        window.scrollTo(0, 0);
                        this.setState({
                            billingIntent: res,
                            stripeCustomerId: res.stripeCustomerId
                        });

                        setTimeout(() => {
                            this.setState({
                                loading: !this.state.loading
                            })
                            this.nextPageHandle()
                        }, 1000);

                    } else {
                        this.setState({ loading: false });
                        console.log(res, '<---ERROR');
                        // alert('Something went wrong');
                    }
                });

            } else {
                this.setState({
                    errorMsg: true
                })
            }
        }
    }

    updateCustomerZap = () => {
        const {
            annualBasePrice, selectedSalesRep, pickupStartDate, specialNotes, interestedInLearningMore, interestedInBecomingADriver, specialInstructions, specialAddressInstructions,
            selectedAreaServceData, email, firstName, lastName, customerType, phoneNumber, glassServiceSelected, timeStamp,
            address1, address2, city, stateName, zipcode, basePrice, glassPrice, typeOfServiceSelected, totalSum, monthlyGlassServiceSelected, glassMonthlyOnlyProvidedCost,
            areaOfService, pickUpSchedule, noSetUpFee, oneMonthFree, discountSetupFee, paymentMethod, paymentFrequency,
        } = this.props

        let servicedAreaData = selectedAreaServceDataFunc(selectedAreaServceData)

        // Reformat date. Example: 12/12/2022
        let pickupDate = pickupStartDate.toString().split(' ')
        let day = pickupDate[2]
        let month = pickupDate[1]
        let monthByNumber
        let year = pickupDate[3]
        let startPickupDate

        let monthsArray = ['Jan,1', 'Feb,2', 'Mar,3', 'April,4', 'May,5', 'Jun,6', 'Jul,7', 'Aug,8', 'Sept,9', 'Oct,10', 'Nov,11', 'Dec,12']
        for (let i = 0; i < monthsArray.length; i++) {
            if (monthsArray[i].split(',')[0] === month) {
                monthByNumber = monthsArray[i].split(',')[1]
            }
        }

        startPickupDate = `${monthByNumber}/${day}/${year}`

        let glassServicesIncluded = false

        if (typeOfServiceSelected === 'monthlyGlassOnly' ||
            (typeOfServiceSelected === 'everyOtherWeek+Glass' ||
                typeOfServiceSelected === 'weekly+Glass' ||
                typeOfServiceSelected === 'monthly+Glass')) {
            glassServicesIncluded = true
        }

        let pickupFrequency = ''

        if (typeOfServiceSelected === 'everyOtherWeek' || typeOfServiceSelected === 'everyOtherWeek+Glass') {
            pickupFrequency = 'Every Other'
        }
        if (typeOfServiceSelected === 'weekly' || typeOfServiceSelected === 'weekly+Glass') {
            pickupFrequency = 'Weekly'
        }
        if (typeOfServiceSelected === 'monthly' || typeOfServiceSelected === 'monthlyGlassOnly' || typeOfServiceSelected === 'monthly+Glass') {
            pickupFrequency = 'Monthly'
        }

        let baseCost = basePrice

        if (paymentFrequency === 'annually') {
            baseCost = annualBasePrice
        } else {
            baseCost = basePrice
        }

        this.setState({
            startPickupDate: monthByNumber ? startPickupDate : '',
            pickupFrequency: pickupFrequency
        })

        const data = {
            door2door: true, // new

            specialNotes: specialNotes.trim(),  // new
            interestedInLearningMore: interestedInLearningMore,  // new
            interestedInBecomingADriver: interestedInBecomingADriver,  // new
            specialInstructions: specialAddressInstructions.trim(),

            selectedSalesRep: selectedSalesRep, // new
            noSetUpFee: noSetUpFee, // new
            oneMonthFree: oneMonthFree, // new
            discountSetupFee: discountSetupFee, // new

            paymentMethod: paymentMethod, // new but not needed now
            // areaOfService: areaOfService, // new but not needed now

            startPickupDate: '',
            email: email.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            customerType: "Residential",// customerType,
            phone: phoneNumber ? phoneNumber.trim() : "",

            stateDisplayName: stateName,
            serviceState: servicedAreaData.serviceState.toUpperCase(),
            serviceCounty: servicedAreaData.serviceCounty,
            serviceLocation: servicedAreaData.locationDisplayName,
            serviceLocationId: servicedAreaData.locationName,

            doNotReceiveMarketingEmails: '',
            address: address1.trim(),
            address2: address2.trim(),
            city: city.trim(),
            zipCode: zipcode.trim(),
            paymentFrequency: paymentFrequency,
            pickupFrequency: pickupFrequency, //pickUpSchedule

            glassPickup: glassServicesIncluded,

            cardBoardServicePrice: '',
            cardBoardPickup: false,

            serviceGlassPrice: glassPrice,
            basePaymentTotal: baseCost,
            howDidYouHearAboutUs: "",

            additionalServices: {
                glass: glassServicesIncluded,
                glassSignUpDate: glassServicesIncluded ? timeStamp : "",
                cardboard: '',
                cardboardSignUpDate: "",
                kitchenOrganics: false,
                kitchenOrganicsSignUpDate: "",
                compost: false,
                compostSignUpDate: "",
            },

            couponCode: '',
            textOptOut: '',
            timestamp: timeStamp,
            locationStatus: servicedAreaData.locationStatus,
            operationName: servicedAreaData.operationDisplayName,
            operationId: servicedAreaData.operationName,
        };
        // ZAPIER put api
        // updateCustomerZap(data)
        // console.log('data2', data)

        // this.setState({ loading: true });

        // set up intent
        let apiBody = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            newCustomer: true
        }

        return apiBody
    };

    render() {
        const { canMoveToNextPage, loading, startDate, errorMsg, billingIntent } = this.state
        const {
            firstName, lastName, email,
            annualRate, monthlyRate, annualBasePrice, basePrice, annualTotalSum,
            serviceInformationFormCompleted, selectedSalesRep, discountHandle, isDiscountSetupFeeVoided, pickupStartDate, setPickupStartDate,
            address1, address2, city, stateName, zipcode, onStepThreeHandle, typeOfServiceSelected, totalSum, monthlyGlassServiceSelected, glassMonthlyOnlyProvidedCost,
            areaOfService, pickUpSchedule, glassServiceSelected, noSetUpFee, oneMonthFree, discountSetupFee, paymentMethod, paymentFrequency,
            areaOfServiceHandle, pickUpScheduleHandle, glassServiceHandle, noSetUpFeeHandle, oneMonthFreeHandle, discountSetupFeeHandle, paymentMethodHandle, paymentFrequencyHandle
        } = this.props


        return (
            <div className="container" style={customerSignupFormStyling.formFont}>
                {!canMoveToNextPage
                    ?
                    <div>
                        <div className="row show_if_mobile_screen hide_if_not_mobile_screen text-left">
                            <div className="col-12" style={customerSignupFormStyling.sectionFontTitle}>
                                <div style={{ fontWeight: 'light bold', fontSize: '1.6vw' }}>
                                    Service Information
                                </div>
                            </div>
                            <div style={{ paddingTop: '5%' }}></div>
                        </div>

                        <div className="row">

                            <div className="col-2 text-left hide_if_mobile_screen" id='serviceInformation' style={customerSignupFormStyling.sectionFontTitle}>
                                Service Information
                            </div>
                            <div className="col-10 text-left">
                                <div className="row">
                                    {/* <div className="col-12">
                                    Area of Service <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                    <div>
                                        <div>
                                            <select id="dropdown" onChange={areaOfServiceHandle} style={customerSignupFormStyling.dropDownStyling}>
                                                <option value="0">Choose</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}

                                    {/* <div style={customerSignupFormStyling.paddingBetweenInputs}></div> */}

                                    {/* <div className="col-12">
                                    Pickup Schedule <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                    <div className="row">
                                        <div className="col-6">
                                            <input
                                                type="radio"
                                                aria-label="Radio button for following text input"
                                                value={`weekly`}
                                                onChange={pickUpScheduleHandle}
                                                name="pickUpSchedule"
                                                checked={pickUpSchedule === 'weekly' ? pickUpSchedule : false}
                                            >
                                            </input>
                                            {` Weekly`}
                                        </div>
                                        <div className="col-6">
                                            <input
                                                type="radio"
                                                aria-label="Radio button for following text input"
                                                value={'everyOther'}
                                                onChange={pickUpScheduleHandle}
                                                name="pickUpSchedule"
                                                checked={pickUpSchedule === 'everyOther' ? pickUpSchedule : false}
                                            >
                                            </input>
                                            {` Every other`}
                                        </div>
                                    </div>
                                </div> */}

                                    {/* <div style={customerSignupFormStyling.paddingBetweenInputs}></div> */}

                                    {/* <div className="col-12">
                                    Glass Recycling
                                    <div>
                                        <div>
                                            <input
                                                type="checkbox"
                                                aria-label="Radio button for following text input"
                                                value={glassServiceSelected}
                                                onClick={glassServiceHandle}
                                                name="glassServiceSelected"
                                                defaultChecked={glassServiceSelected ? glassServiceSelected : glassServiceSelected}
                                            >
                                            </input>
                                            {` Sign Up for glass recycling`}
                                        </div>
                                    </div>
                                </div> */}

                                    {/* <div style={customerSignupFormStyling.paddingBetweenInputs}></div> */}

                                    {/* <div className="col-12" >
                                        <div style={customerSignupFormStyling.inputTitleStyle}>
                                            First Pick up Date
                                            <div style={customerSignupFormStyling.paddingBetweenTitleAndInputs}></div>
                                        </div>
                                        https://www.npmjs.com/package/react-datepicker
                                        <DatePicker
                                            placeholderText="Manual or calendar?"
                                            selected={pickupStartDate}
                                            onChange={date => setPickupStartDate(date)}
                                        />
                                    </div> */}

                                    {/* <div style={customerSignupFormStyling.paddingBetweenTitleAndInputs}></div> */}
                                    {/* <div style={customerSignupFormStyling.paddingBetweenInputs}></div> */}

                                    <div className="col-12">
                                        <div style={customerSignupFormStyling.inputTitleStyle}>
                                            Payment Frequency <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                            <div style={customerSignupFormStyling.paddingBetweenTitleAndInputs}></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <input
                                                    type="radio"
                                                    aria-label="Radio button for following text input"
                                                    value={`monthly`}
                                                    // id="quarterlySelection"
                                                    onChange={paymentFrequencyHandle}
                                                    name="paymentFrequency"
                                                    checked={paymentFrequency === 'monthly' ? paymentFrequency : false}
                                                >
                                                </input>
                                                {
                                                    basePrice === annualBasePrice
                                                        ? ` Monthly`
                                                        : ` Monthly ($${totalSum})`
                                                }
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    type="radio"
                                                    aria-label="Radio button for following text input"
                                                    value={`quarterly`}
                                                    id="quarterlySelection"
                                                    onChange={paymentFrequencyHandle}
                                                    name="paymentFrequency"
                                                    checked={paymentFrequency === 'quarterly' ? paymentFrequency : false}
                                                >
                                                </input>
                                                {
                                                    basePrice === annualBasePrice
                                                        ? ` Quarterly`
                                                        : ` Quarterly ($${totalSum} / month)`
                                                }
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    type="radio"
                                                    aria-label="Radio button for following text input"
                                                    value={`annually`}
                                                    id="annuallySelection"
                                                    onChange={paymentFrequencyHandle}
                                                    name="paymentFrequency"
                                                    checked={paymentFrequency === 'annually' ? paymentFrequency : false}
                                                >
                                                </input>
                                                {
                                                    basePrice === annualBasePrice
                                                        ? ` Annually`
                                                        : ` Annually ($${annualTotalSum} / month)`

                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div style={customerSignupFormStyling.paddingBetweenInputs}></div>

                                    {/* <div className="col-12">
                                    Payment method  <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                    <div className="row">
                                        <div className="col-6">
                                            <input
                                                type="radio"
                                                aria-label="Radio button for following text input"
                                                value={'card'}
                                                onChange={paymentMethodHandle}
                                                name="paymentMethod"
                                                checked={paymentMethod === 'card' ? paymentMethod : false}
                                            >
                                            </input>
                                            {` Card (filled out after clicking submit)`}
                                        </div>
                                        <div className="col-6">
                                            <input
                                                type="radio"
                                                aria-label="Radio button for following text input"
                                                value={`venmoAnnually`}
                                                onChange={paymentMethodHandle}
                                                name="paymentMethod"
                                                checked={paymentMethod === 'venmoAnnually' ? paymentMethod : false}
                                            >
                                            </input>
                                            {` Venmo (Annually)`}
                                        </div>
                                    </div>
                                </div> */}

                                    <div style={customerSignupFormStyling.paddingBetweenInputs}></div>

                                    <div className="col-12">
                                        <div style={customerSignupFormStyling.inputTitleStyle}>
                                            Select all that apply
                                            <div style={customerSignupFormStyling.paddingBetweenTitleAndInputs}></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        aria-label="Radio button for following text input"
                                                        value={'noSetUpFee'}
                                                        id="noSetUpFee"
                                                        onClick={() => discountHandle('noSetUpFee')}
                                                        defaultChecked={noSetUpFee ? noSetUpFee : noSetUpFee}
                                                        disabled={isDiscountSetupFeeVoided}
                                                    >
                                                    </input>
                                                    {` No setup fee`}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    type="checkbox"
                                                    aria-label="Radio button for following text input"
                                                    value={'oneMonthFree'}
                                                    id='oneMonthFree'
                                                    onClick={() => discountHandle('oneMonthFree')}
                                                    name='oneMonthFree'
                                                    defaultChecked={oneMonthFree ? oneMonthFree : oneMonthFree}
                                                >
                                                </input>
                                                {` One month free`}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={customerSignupFormStyling.paddingBetweenInputs}></div>

                                    <div className="col-12">
                                        <div style={customerSignupFormStyling.inputTitleStyle}>
                                            Discount setup fee
                                            <div style={customerSignupFormStyling.paddingBetweenTitleAndInputs}></div>
                                        </div>
                                        <div>
                                            <div>
                                                <select disabled={noSetUpFee} id="dropDownDiscount" onChange={discountSetupFeeHandle} style={customerSignupFormStyling.dropDownStyling}>
                                                    <option value={0}>Choose</option>
                                                    <option value={.10}>10%</option>
                                                    <option value={.20}>20%</option>
                                                    <option value={.30}>30%</option>
                                                    <option value={.40}>40%</option>
                                                    <option value={.50}>50%</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={customerSignupFormStyling.paddingBetweenInputs}></div>

                                </div>
                            </div>

                            <div className="col-1">
                            </div>

                            <div style={customerSignupFormStyling.paddingBetweenInputs}></div>

                            <br />
                            <br />
                            <br />

                            <div className='container'>

                                <div style={customerSignupFormStyling.errorMsgColor}>{errorMsg ? `Please Select a Payment Frequency` : ''}</div>

                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>

                                <div className="row">

                                    <div className="col-6 text-left" onClick={this.props.previousPage}>
                                        <div className="row">
                                            <div className="col-sm text-center" id='previousPageButton' style={customerSignupFormStyling.buttonForNextPage}>
                                                Back
                                            </div>
                                            <div className="col-sm">
                                            </div>
                                            <div className="col-sm">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 text-right"
                                        id="submitHandle"
                                        onClick={this.submitHandle}
                                    >
                                        <div className="row">
                                            <div className="col-sm">
                                            </div>
                                            <div className="col-sm">
                                            </div>
                                            <div className="col-sm text-center" style={customerSignupFormStyling.buttonForNextPage}>
                                                {loading ? 'loading...' : 'Submit'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    // console.log('billing hidden')
                    <BillingForm
                        address1BillingHandle={this.address1BillingHandle}
                        address2BillingHandle={this.address2BillingHandle}
                        cityBillingHandle={this.cityBillingHandle}
                        stateNameBillingHandle={this.stateNameBillingHandle}
                        zipCodeBillingHandle={this.zipCodeBillingHandle}

                        sameAsBillingAddressHandle={this.sameAsBillingAddressHandle}
                        sameAsBillingAddress={this.state.sameAsBillingAddress}
                        billingStreetAddress1={this.state.billingStreetAddress1}
                        billingStreetAddress2={this.state.billingStreetAddress2}
                        billingCityName={this.state.billingCityName}
                        billingStateName={this.state.billingStateName}
                        billingZipCode={this.state.billingZipCode}

                        signupStepTwoHandle={this.signupStepTwoHandle}
                        serviceInformationFormHandle={this.props.serviceInformationFormHandle}
                        onStepThreeHandle={onStepThreeHandle}
                        previousPage={this.nextPageHandle}
                        address1={address1}
                        address2={address2}
                        city={city}
                        stateName={stateName}
                        zipcode={zipcode}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}

                        billingIntent={billingIntent}

                        typeOfServiceSelected={typeOfServiceSelected}
                        totalSum={totalSum}
                        monthlyGlassServiceSelected={monthlyGlassServiceSelected}
                        glassMonthlyOnlyProvidedCost={glassMonthlyOnlyProvidedCost}
                    />
                }

            </div >
        );
    }
}

export default ServiceInformationForm