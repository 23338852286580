const initialState = {
  location: {
    isLocationSelected: false,
    locationSelected: '',
    locationDisplayName: '',
    selectedAreaData: []
  }
}

const locationSelected = (state = initialState, action) => {
  const newState = {
    ...state
  }

  if (action.type === 'LOCATION_SELECTED') {
    newState.location = action.selectedLocation
  }

  return newState

};


export default locationSelected
