/* eslint-disable */
import React, { Component } from "react";
import serviceStyling from './serviceStyling'

class InformationSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }
    render() {
        const { typeOfServiceSelected, monthlyRate, glassPickupSchedules, glassMonthlyRate, basicPickupSchedules } = this.props

        return (
            <div>
                {/* {typeOfServiceSelected === 'weekly' || typeOfServiceSelected === 'weekly+Glass' ? 'Weekly' : ''}
                {typeOfServiceSelected === 'everyOtherWeek' || typeOfServiceSelected === 'everyOtherWeek+Glass' ? 'Every other' : ''}
                {typeOfServiceSelected === 'monthly' || typeOfServiceSelected === 'monthlyGlassOnly' || typeOfServiceSelected === 'monthly+Glass' ? 'Monthly' : ''} */}

                {typeOfServiceSelected === 'weekly' || typeOfServiceSelected === 'weekly+Glass'
                    ? <div>
                        <div className=''>
                            <br />
                            <div style={serviceStyling.typeOfServiceFontSize}>
                                Standard Pick Up
                            </div>
                            <div style={serviceStyling.monthlyCostStyle}>{`${monthlyRate.weekly !== 0 ? `$${monthlyRate.weekly} / month` : ''}`}</div>
                        </div>

                        {typeOfServiceSelected === 'weekly+Glass'
                            ? <div className=''>
                                <br />
                                <div>{
                                    !glassPickupSchedules.weekly
                                        ? ''
                                        : <div className="">
                                            <div style={serviceStyling.typeOfServiceFontSize}>
                                                Standard + Glass
                                            </div>
                                            <div>
                                                <div style={serviceStyling.monthlyCostStyle}>
                                                    {`${glassPickupSchedules.weekly
                                                        ? monthlyRate.weekly !== 0
                                                            ? `$${glassMonthlyRate.weekly !== 0
                                                                ? glassMonthlyRate.weekly + monthlyRate.weekly
                                                                : ''} / month`
                                                            : '' : 'Does not offer glass'
                                                        }`}</div>
                                            </div>
                                        </div>
                                }</div>
                            </div>
                            : ''
                        }

                        {basicPickupSchedules.everyOther ? <div>
                            <br />
                        </div> : null}

                    </div>
                    : ''}

                {/*  */}

                {typeOfServiceSelected === 'everyOtherWeek' || typeOfServiceSelected === 'everyOtherWeek+Glass'
                    ? <div>
                        <div className=''>
                            <br />
                            <div style={serviceStyling.typeOfServiceFontSize}>
                                Standard Pick Up
                            </div>
                            <div style={serviceStyling.monthlyCostStyle}>{`${monthlyRate.everyOther !== 0 ? `$${monthlyRate.everyOther} / month` : ''}`}</div>
                        </div>

                        {typeOfServiceSelected === 'everyOtherWeek+Glass'
                            ? <div className=''>
                                <br />
                                <div>{
                                    !glassPickupSchedules.everyOther
                                        ? ''
                                        : <div className="">
                                            <div style={serviceStyling.typeOfServiceFontSize}>
                                                Standard + Glass
                                            </div>
                                            <div>
                                                <div style={serviceStyling.monthlyCostStyle}>
                                                    {`${glassPickupSchedules.everyOther
                                                        ? monthlyRate.everyOther !== 0
                                                            ? `$${glassMonthlyRate.everyOther !== 0
                                                                ? glassMonthlyRate.everyOther + monthlyRate.everyOther
                                                                : ''} / month`
                                                            : '' : 'Does not offer glass'
                                                        }`}</div>
                                            </div>
                                        </div>
                                }</div>
                            </div>
                            : ''
                        }

                        {basicPickupSchedules.everyOther ? <div>
                            <br />
                        </div> : null}

                    </div>
                    : ''}

                {/*  */}

                {typeOfServiceSelected === 'monthly' || typeOfServiceSelected === 'monthly+Glass'
                    ? <div>
                        <div className=''>
                            <br />
                            <div style={serviceStyling.typeOfServiceFontSize}>
                                Standard Pick Up 1
                            </div>
                            <div style={serviceStyling.monthlyCostStyle}>{`${monthlyRate.monthly !== 0 ? `$${monthlyRate.monthly} / month` : ''}`}</div>
                        </div>

                        {typeOfServiceSelected === 'monthly+Glass'
                            ? <div className=''>
                                <br />
                                <div>{
                                    !glassPickupSchedules.monthly
                                        ? ''
                                        : <div className="">
                                            <div style={serviceStyling.typeOfServiceFontSize}>
                                                Standard + Glass
                                            </div>
                                            <div>
                                                <div style={serviceStyling.monthlyCostStyle}>
                                                    {`${glassPickupSchedules.monthly
                                                        ? monthlyRate.monthly !== 0
                                                            ? `$${glassMonthlyRate.monthly !== 0
                                                                ? glassMonthlyRate.monthly + monthlyRate.monthly
                                                                : ''} / month`
                                                            : '' : 'Does not offer glass'
                                                        }`}</div>
                                            </div>
                                        </div>
                                }</div>
                            </div>
                            : ''
                        }

                        {basicPickupSchedules.everyOther ? <div>
                            <br />
                        </div> : null}

                    </div>
                    : ''}
            </div >
        );
    }
}

export default InformationSection