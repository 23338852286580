const baseUrl = process.env.REACT_APP_RECYCLOPS_COM_BFF_URL;

const signupStepOne = (data, cb) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify(data);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    fetch(`${baseUrl}/signup/stepone`, requestOptions)
        .then(response => response.text())
        .then(result => cb(JSON.parse(result)))
        .catch(error => console.log('error', error));
};

const billingSetupIntent = (data, cb) => {
    const raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: raw,
        redirect: 'follow',
    };

    fetch(`${baseUrl}/billing/setup-intent`, requestOptions)
        .then(response => response.text())
        .then(result => cb(JSON.parse(result)))
        .catch(error => console.log('error', error));
};

const signupStepTwo = (data, cb) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify(data);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    fetch(`${baseUrl}/signup/steptwo`, requestOptions)
        .then(response => response.text())
        .then(result => cb(JSON.parse(result)))
        .catch(error => console.log('error', error));
};

export {
    signupStepOne,
    billingSetupIntent,
    signupStepTwo
};
