const serviceStyling = {
    centerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    iconStyling: {
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid #C4C4C4',
        borderRadius: '20px',
        paddingTop: '20%',
        paddingBottom: '20%',
        // paddingLeft: '20%'
    },
    headerStyle: {
        background: '#3CB4AA',
        width: '100%',
        border: '1px solid #C4C4C4',
        borderRadius: '20px',
        padding: '2%'
    },
    headerSpacing: {
        padding: '.8%'
    },
    headerFont: {
        color: 'white',
        fontSize: '1.6vw'
    },
    dropDownStyling: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        borderRadius: '0px',
        background: '#3CB4AA',
        // width: '53%',
        color: 'white',
        fontSize: '1.6vw'
    },
    infoReportContainer: {
        border: '1px solid #3CB4AA',
        borderRadius: '20px',
    },
    boxHeaders: {
        background: '#3CB4AA',
        borderRadius: '20px 20px 0px 0px',
        boxSizing: 'border-box',
        color: 'white',
        padding: '2%',
        fontSize: '1.6vw'

    },
    desciptionFont: {
        color: '#848484',
        fontSize: '.9vw',
    },
    paymentFrequencyCostStyle: {
        color: ' #2A9B8D',
        fontSize: '1.6vw',
    },
    setupFeeStyle: {
        color: ' #2A9B8D',
        fontSize: '1.2vw',
    },
    selectedButton: {
        background: '#FCC816',
        borderRadius: '20px',
        width: '42%',
        padding: '1.5%',
        color: 'white'
    },
    selectButton: {
        background: '#2A9B8D',
        borderRadius: '20px',
        width: '35%',
        padding: '1.5%',
        color: 'white'
    },
    selectedButtonSizeNoGlass: {
        background: '#FCC816',
        borderRadius: '20px',
        width: '22%',
        padding: '1.5%',
        color: 'white'
    },
    selectButtonSizeNoGlass: {
        background: '#2A9B8D',
        borderRadius: '20px',
        width: '20%',
        padding: '1.5%',
        color: 'white'
    },
    selectedButtonMonthly: {
        background: '#FCC816',
        borderRadius: '20px',
        width: '22%',
        padding: '1.5%',
        color: 'white'
    },
    selectButtonMonthly: {
        background: '#2A9B8D',
        borderRadius: '20px',
        width: '20%',
        padding: '1.5%',
        color: 'white'
    },
    verticalDivider: {
        borderLeft: '1px solid #E7E7E7'
    },
    // columnContainer: {
    //     border: '1px solid #C4C4C4',
    //     borderRadius: '20px',
    // },
    columnRightAndLeftContainer: {
        border: '1px solid #C4C4C4',
        borderRadius: '20px',
        marginLeft: '-1vw',
        marginRight: '-1vw',
        height: '100%',
    },
    columnCenterContainer: {
        border: '1px solid #C4C4C4',
        borderRadius: '20px',
        height: '100%',
    },
    stepsButton: {
        background: '#FFFFFF',
        borderRadius: '20px',
        border: '1px solid #2A9B8D',
        width: '100%',
        padding: '3%',
        color: '#2A9B8D'
    },
    stepsButtonGreen: {
        background: '#3CB4AA',
        borderRadius: '20px',
        border: '1px solid #3CB4AA',
        width: '100%',
        padding: '3%',
        color: '#FFFFFF'
    },
    SubsequentTitleFont: {
        fontSize: '65%',
        color: '#848484',
    },
    summaryTitleFont: {
        fontSize: '75%',
        color: '#848484',
    },
    monthlyCostStyle: {
        color: ' #2A9B8D',
        // fontSize: '1.6vw',
        fontSize: '20px',
    },
    titleFont: {
        color: 'black',
        fontSize: '1.6vw'
    },
    formTitleFont: {
        color: 'black', fontWeight: 'light bold'
    },
    monthlyCost: {
        color: 'black',
        fontSize: '75%'
    },
    titleFontForLargeTitle: {
        color: 'black',
        fontSize: '75%'
    },
    titleFontForLargeTitle1: {
        color: 'black',
        fontSize: '80%'
    },
    titleFontForLargeTitle2: {
        color: 'black',
        fontSize: '71%'
    },
    spacingBetweenContent: {
        padding: '2%'
    },
    spacingBetweenDiv: {
        paddingBottom: '5%'
    },
    extraPaddingBottom: {
        paddingBottom: '1%'
    },
    totalSumSpacing: {
        padding: '9%'
    },
    customerFormLayout: {
        background: '#FFFFFF',
        border: '1px solid #2A9B8D',
        width: '100%',
        padding: '3%',
        color: '#2A9B8D'
    },
    customerFormHeader: {
        background: '#FFFFFF',
        borderRadius: '20px 20px 0 0',
        border: '1px solid #3CB4AA',
        width: '100%',
        color: '#3CB4AA',
        borderBottom: '0'
    },
    customerFormHeaderStep1: {
        background: '#3CB4AA',
        borderRadius: '20px 20px 0 0',
        width: '80%',
        padding: '1%',
        color: 'white'
    },
    headerStep1: {
        background: '#3CB4AA',
        borderRadius: '20px 0 0 0',
        width: '80%',
        padding: '1%',
        color: 'white'
    },
    headerStep2: {
        background: '#3CB4AA',
        borderRadius: '0 0 0 0',
        width: '80%',
        padding: '1%',
        color: 'white'
    },
    customerFormHeaderStep1white: {
        background: '#FFFFFF',
        borderRadius: '20px 20px 0 0',
        width: '80%',
        padding: '1%',
        color: '#3CB4AA',
    },
    customerFormHeaderNextStep: {
        background: '#3CB4AA',
        borderRadius: '0 20px 0 0',
        width: '80%',
        padding: '1%',
        color: 'white'
    },
    customerFormHeaderNextStepWhite: {
        background: '#FFFFFF',
        borderRadius: '0 20px 0 0',
        width: '80%',
        padding: '1%',
        color: '#3CB4AA',
    },
    customerFormHeaderPreviousStep: {
        background: '#3CB4AA',
        borderRadius: '20px 0 0 0',
        width: '80%',
        padding: '1%',
        color: 'white'
    },
    toggleButtonToCustomerSignUp: {
        color: '#2A9B8D',
        fontSize: '160%',
        background: 'white',
    },
    toggleButtonPositionCustomerSignUp: {
        position: 'absolute', left: '-3%', top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    toggleButtonToServices: {
        color: '#2A9B8D',
        fontSize: '160%',
        background: 'white'
    },
    toggleButtonPositionServices: {
        position: 'absolute',
        left: '-.12%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    typeOfServiceFontSize: {
        fontSize: '75%'
    },
    discountTitleDivider: {
        backgroundColor: 'black',
        height: '3',
        fontSize: "10%",
        color: 'black',
    },
    discountTitleDividerMobile: {
        backgroundColor: '#C4C4C4',
        height: '3',
        fontSize: "5%",
        color: '#C4C4C4',
    },
    scrollBarContainer: {
        position: 'relative',
        // height: '420px',
        height: '59vw',
        overflow: 'scroll',
        // position: 'relative',
        // height: '650px',
        // overflow: 'scroll',
        // marginBottom: '100px'
    },
    errorMsg: {
        color: 'red'
    }
};




export default serviceStyling