/* eslint-disable */
import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import ConfirmCode from './confirmCode'

class EnterEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codeSent: false,
            email: '',
            forgotPasswordClicked: false,
        };
    }

    emailCodeHandle = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    forgotPasswordHandle = () => {
        this.setState({
            forgotPasswordClicked: !this.state.forgotPasswordClicked
        })
    }

    reSetPasswordHandle = async (event) => {
        event.preventDefault();
        const { email } = this.state
        if (email) {
            await Auth.forgotPassword(email);
            this.setState({
                codeSent: true,
                email: email
            })
        }
    }

    render() {
        const { forgotPasswordClicked, codeSent, email } = this.state
        return (
            <div>
                {codeSent
                    ? <ConfirmCode email={email} />
                    : !forgotPasswordClicked
                        ? <button onClick={() => this.forgotPasswordHandle()}>Forgot Password</button>
                        :
                        <form onSubmit={this.reSetPasswordHandle}>
                            <div>
                                Email
                            </div>
                            <input type="text" name="email" value={this.state.email} onChange={this.emailCodeHandle} />
                            <input type="submit" value="Submit" />
                        </form>
                }
            </div>
        );
    }
}

export default EnterEmail
