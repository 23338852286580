/* eslint-disable */
import React, { Component } from "react";
import customerSignupFormStyling from './customerSignupFormStyling'
import { Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import ServiceInformationForm from './serviceInformationForm'
import TimeStamp from '../helpers/timeStamp/timeStamp'
import { selectedAreaServceDataFunc } from '../helpers/selected-area-servce-data/selectedAreaServceData'
import { createCustomerZap } from '../helpers/zapier/CreateCustomerZap'
import { signupStepOne } from '../../api'
import stateSelections from '../helpers/stateSelections/stateSelections';

class CustomerSignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canMoveToNextPage: false,
            timeStamp: TimeStamp(Math.floor(Date.now())),
            errorMsg: false,
            isLoading: false
        };
    }

    componentDidMount() {
        const { timeStamp } = this.state
        let timeStampData = `${timeStamp.split('-')[1]}/${timeStamp.split('-')[2]}/${timeStamp.split('-')[0]}`

        this.setState({
            timeStamp: timeStampData
        })

        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    nextPageHandle = () => {
        const { canMoveToNextPage } = this.state
        const { hubSpotIdHandler, typeOfServiceSelected, selectedAreaServceData, selectedSalesRep, onStepTwoHandle, address1, address2, city, stateName, zipcode, firstName, lastName, email, emailConfirmation, phoneNumber, interestedInLearningMore, interestedInBecomingADriver, customerSignupFormHandle, customerSignupFormCompleted, customerSignupFormDisableHandle, specialAddressInstructions, specialNotes, serviceInformationFormHandle } = this.props

        if (email.length > 0 && emailConfirmation.length > 0) {
            if (selectedSalesRep.length > 0 && emailConfirmation === email &&
                address1.length > 0 && city.length > 0 && stateName.length > 0 &&
                zipcode.length > 0 && firstName.length > 0 && lastName.length > 0
                && phoneNumber.length > 0
            ) {

                let typeOfServiceVal = ''
                if (typeOfServiceSelected === 'everyOtherWeek+Glass' || typeOfServiceSelected === 'everyOtherWeek') {
                    typeOfServiceVal = 'every_other'
                }
                if (typeOfServiceSelected === 'weekly+Glass' || typeOfServiceSelected === 'weekly') {
                    typeOfServiceVal = 'weekly'
                }
                if (typeOfServiceSelected === 'monthly+Glass' || typeOfServiceSelected === 'monthly') {
                    typeOfServiceVal = 'monthly'
                }

                let servicedAreaData = selectedAreaServceDataFunc(selectedAreaServceData)

                let ifGlassServiceExist = ''
                if (typeOfServiceSelected.includes("+")) {
                    ifGlassServiceExist = typeOfServiceSelected.split('+')
                    if (ifGlassServiceExist.includes("Glass")) {
                        ifGlassServiceExist = 'glass'
                    }
                }

                let body = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber,
                    customerType: "residential",
                    locationId: servicedAreaData.operationName,
                    operationDisplayName: servicedAreaData.operationDisplayName,
                    locationName: servicedAreaData.locationName,
                    serviceCounty: servicedAreaData.serviceCounty,
                    serviceState: servicedAreaData.serviceState,
                    serviceAddress: {
                        line1: address1,
                        line2: address2,
                        city: city,
                        state: stateName,
                        postalCode: zipcode
                    },
                    door2door: true,
                    salesRepName: selectedSalesRep,
                    interestedInBecomingADriver: interestedInBecomingADriver,
                    interestedInLearningMore: interestedInLearningMore,
                    specialNotes: specialNotes,
                    specialInstructions: specialAddressInstructions,
                    pickupFrequency: typeOfServiceVal,
                    servicesSelected: ["mixed_recycling", ifGlassServiceExist]
                }
                this.setState({
                    isLoading: true
                })
                // api call here
                signupStepOne(body, res => {
                    if (res.hubspotContactId && res.hubspotDealId) {
                        if (interestedInLearningMore) {
                            serviceInformationFormHandle()
                        } else {
                            hubSpotIdHandler(res.hubspotContactId, res.hubspotDealId)
                            this.setState({
                                isLoading: false
                            })
                        }
                    } else {
                        console.log(res, '<---ERROR');
                        alert('Something went wrong');
                    }
                });

                // this.postToZapier()

                if (!interestedInLearningMore) {
                    onStepTwoHandle()

                    if (customerSignupFormCompleted === false) {
                        customerSignupFormHandle()
                    }

                    this.setState({
                        canMoveToNextPage: !canMoveToNextPage,
                        errorMsg: false
                    })
                }

            } else {
                console.log('no selectedSalesRep or email error')
                customerSignupFormDisableHandle()

                this.setState({
                    errorMsg: true
                })
            }
        } else {
            this.setState({
                errorMsg: true
            })
        }
    }
    postToZapier = () => {
        const { selectedSalesRep, selectedAreaServceData, firstName, lastName, email, phoneNumber, address1, address2, zipcode, specialNotes, city, specialAddressInstructions, interestedInLearningMore, interestedInBecomingADriver, emailConfirmation } = this.props
        const { timeStamp } = this.state

        let servicedAreaData = selectedAreaServceDataFunc(selectedAreaServceData)

        const data = {
            door2door: true,

            selectedSalesRep: selectedSalesRep, // new /
            specialNotes: specialNotes.trim(),  // new
            interestedInLearningMore: interestedInLearningMore,  // new
            interestedInBecomingADriver: interestedInBecomingADriver,  // new

            specialInstructions: specialAddressInstructions.trim(),
            customerType: 'Residential',
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            isCommercial: false,
            email: email.trim(),
            phone: phoneNumber.trim(),
            stateDisplayName: servicedAreaData.stateDisplayName,
            serviceState: servicedAreaData.serviceState.toUpperCase(),
            serviceCounty: servicedAreaData.serviceCounty,
            serviceLocation: servicedAreaData.locationDisplayName ? servicedAreaData.locationDisplayName : '',
            serviceLocationId: servicedAreaData.locationName ? servicedAreaData.locationName : '',
            timestamp: timeStamp,
            address: address1.trim(),
            address2: address2.trim(),
            zipCode: zipcode.trim(),

            city: city.trim(),
            locationStatus: servicedAreaData.locationStatus,
            operationName: servicedAreaData.operationDisplayName,
            operationId: servicedAreaData.operationName,

            businessName: '',
            onDemandSelected: false,
            newLocationRequested: false,
            locationRequestState: '',
            locationRequestCountyCity: '',
            textOptOut: false,
            doNotReceiveMarketingEmails: false,
            doesNotOfferCurbSide: false,
            wantToRecycleGlass: false,
            wantToRecyclePlastic: false,
            wantCompostRecycling: false,
            noCondoRecycling: false,
            otherCheckBoxOption: false,
            onDemandRecyclingSupplement: false,
            otherCheckBoxOptionReason: '',
        };
        // ZAPIER post api
        // createCustomerZap(data);
        // console.log('data', data)
    }

    // handleSubmit(event) {
    //     alert('A name was submitted: ');
    //     event.preventDefault();
    // }

    render() {
        const { canMoveToNextPage, errorMsg, timeStamp, isLoading } = this.state
        const {
            selectedSalesRep,

            selectedAreaServceData,

            toggleCustomerSignUpHandle,
            onStepThreeHandle,
            typeOfServiceSelected,
            totalSum,
            monthlyGlassServiceSelected,
            glassMonthlyOnlyProvidedCost,

            stateDisplayName,
            serviceState,
            serviceCounty,
            serviceLocation,
            serviceLocationId,
            timestamp,

            address1Handle,
            address2Handle,
            cityHandle,
            stateNameHandle,
            zipCodeHandle,
            firstNameHandle,
            lastNameHandle,
            emailHandle,
            emailConfirmationHandle,
            phoneNumberHandle,
            specialAddressInstructionsHandle,
            salesRepNameHandle,
            specialNotesHandle,
            reviewedAndConfirmHandle,
            interestedInLearningMoreHandle,
            interestedInBecomingADriverHandle,

            specialInstructions,
            address1,
            address2,
            city,
            stateName,
            zipcode,
            firstName,
            lastName,
            email,
            emailConfirmation,
            phoneNumber,
            specialAddressInstructions,
            salesRepName,
            specialNotes,
            reviewedAndConfirm,
            interestedInLearningMore,
            interestedInBecomingADriver,

            areaOfService,
            pickUpSchedule,
            glassServiceSelected,
            noSetUpFee,
            oneMonthFree,
            discountSetupFee,
            paymentMethod,
            paymentFrequency,

            discountHandle,
            selectSalesRepFeeHandle,
            areaOfServiceHandle,
            pickUpScheduleHandle,
            glassServiceHandle,
            noSetUpFeeHandle,
            oneMonthFreeHandle,
            discountSetupFeeHandle,
            paymentMethodHandle,
            paymentFrequencyHandle,
            basePrice,
            annualBasePrice,
            glassPrice,
            isDiscountSetupFeeVoided,
            pickupStartDate,
            setPickupStartDate,
            customerSignupFormCompleted,
            serviceInformationFormCompleted,
            serviceInformationFormHandle,

            annualTotalSum,
            annualRate,
            monthlyRate,

            hubspotContactId,
            hubspotDealId

        } = this.props

        return (
            <form>
                <div>
                    {/* CustomerSignupForm */}
                    <div>
                        <Element className="element" id="scroll-container" style={customerSignupFormStyling.scrollbarContainer}>
                            <Element name="scroll-container-first-element" style={customerSignupFormStyling.scrollbarMargin}>
                                <div>
                                    {canMoveToNextPage && customerSignupFormCompleted
                                        ? <div>
                                            <ServiceInformationForm
                                                hubspotContactId={hubspotContactId}
                                                hubspotDealId={hubspotDealId}
                                                selectedSalesRep={selectedSalesRep}
                                                timeStamp={timeStamp}

                                                email={email}
                                                firstName={firstName}
                                                lastName={lastName}
                                                customerType={'residential'}
                                                phoneNumber={phoneNumber}

                                                selectedAreaServceData={selectedAreaServceData}

                                                annualRate={annualRate}
                                                monthlyRate={monthlyRate}

                                                stateDisplayName={stateDisplayName}
                                                serviceState={serviceState}
                                                serviceCounty={serviceCounty}
                                                serviceLocation={serviceLocation}
                                                serviceLocationId={serviceLocationId}

                                                address1={address1}
                                                address2={address2}
                                                city={city}
                                                stateName={stateName}
                                                zipcode={zipcode}
                                                previousPage={this.nextPageHandle}
                                                onStepThreeHandle={onStepThreeHandle}

                                                typeOfServiceSelected={typeOfServiceSelected}
                                                totalSum={totalSum}
                                                monthlyGlassServiceSelected={monthlyGlassServiceSelected}
                                                glassMonthlyOnlyProvidedCost={glassMonthlyOnlyProvidedCost}

                                                specialAddressInstructions={specialAddressInstructions}
                                                specialInstructions={specialInstructions}
                                                interestedInBecomingADriver={interestedInBecomingADriver}
                                                interestedInLearningMore={interestedInLearningMore}
                                                specialNotes={specialNotes}
                                                areaOfService={areaOfService}
                                                pickUpSchedule={pickUpSchedule}
                                                glassServiceSelected={glassServiceSelected}
                                                noSetUpFee={noSetUpFee}
                                                oneMonthFree={oneMonthFree}
                                                discountSetupFee={discountSetupFee}
                                                paymentMethod={paymentMethod}
                                                paymentFrequency={paymentFrequency}
                                                isDiscountSetupFeeVoided={isDiscountSetupFeeVoided}
                                                pickupStartDate={pickupStartDate}
                                                serviceInformationFormCompleted={serviceInformationFormCompleted}

                                                serviceInformationFormHandle={serviceInformationFormHandle}
                                                setPickupStartDate={setPickupStartDate}
                                                discountHandle={discountHandle}

                                                areaOfServiceHandle={areaOfServiceHandle}
                                                pickUpScheduleHandle={pickUpScheduleHandle}
                                                glassServiceHandle={glassServiceHandle}
                                                noSetUpFeeHandle={noSetUpFeeHandle}
                                                oneMonthFreeHandle={oneMonthFreeHandle}
                                                discountSetupFeeHandle={discountSetupFeeHandle}
                                                paymentMethodHandle={paymentMethodHandle}
                                                paymentFrequencyHandle={paymentFrequencyHandle}

                                                annualTotalSum={annualTotalSum}
                                                annualBasePrice={annualBasePrice}
                                                basePrice={basePrice}
                                                glassPrice={glassPrice}
                                            />
                                        </div>
                                        : <div>
                                            <div className="container" style={customerSignupFormStyling.formFont}>
                                                <div className="row show_if_mobile_screen hide_if_not_mobile_screen text-left">
                                                    <div className="col-12" style={customerSignupFormStyling.sectionFontTitle}>
                                                        <div style={{ fontWeight: 'light bold', fontSize: '1.6vw' }}>
                                                            Customer Information
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: '5%' }}></div>
                                                </div>

                                                <div className="row">

                                                    <div className="col-2 text-left hide_if_mobile_screen" id='customerInformation' style={customerSignupFormStyling.sectionFontTitle}>
                                                        Customer Information
                                                    </div>

                                                    <div className="col-12 col-lg-10">
                                                        {/* form starts here */}
                                                        <div className="row text-left">
                                                            <div className="col-12" style={customerSignupFormStyling.inputTitleStyle}>
                                                                Address line 1 <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="address"
                                                                    className="form-control placeholderText"
                                                                    id="address1Handle"
                                                                    placeholder="Enter Address"
                                                                    value={address1}
                                                                    onChange={address1Handle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-12" style={customerSignupFormStyling.inputTitleStyle}>
                                                                <div style={{ padding: '1.6%' }}></div>
                                                                Address line 2
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="address"
                                                                    className="form-control placeholderText"
                                                                    id="address2Handle"
                                                                    placeholder="Enter Address"
                                                                    value={address2}
                                                                    onChange={address2Handle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6" >
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    City <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="text"
                                                                    className="form-control placeholderText"
                                                                    id="cityInput"
                                                                    placeholder="Enter City"
                                                                    value={city}
                                                                    onChange={cityHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    State <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <select
                                                                    id='stateInput'
                                                                    className="form-control placeholderText"
                                                                    onChange={stateNameHandle}
                                                                    value={stateName}
                                                                    style={customerSignupFormStyling.dropDownStateNameStyling}
                                                                >
                                                                    <option value="">
                                                                        {stateName ? stateName : `Choose`}
                                                                    </option>
                                                                    {stateSelections
                                                                        ? stateSelections.map((state, i) => {
                                                                            return (
                                                                                <option key={i} value={state.value} >
                                                                                    {state.value}
                                                                                </option>
                                                                            );
                                                                        })
                                                                        : ''}
                                                                </select>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    Zip code <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="text"
                                                                    className="form-control placeholderText"
                                                                    id="zipcode"
                                                                    placeholder="Enter Zip Code"
                                                                    value={zipcode}
                                                                    onChange={zipCodeHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                {/* blank */}
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    First name <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="text"
                                                                    className="form-control placeholderText"
                                                                    id="firstName"
                                                                    placeholder="Enter First Name"
                                                                    value={firstName}
                                                                    onChange={firstNameHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    Last name <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="text"
                                                                    className="form-control placeholderText"
                                                                    id="lastName"
                                                                    placeholder="Enter Last Name"
                                                                    value={lastName}
                                                                    onChange={lastNameHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    Email <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <span style={customerSignupFormStyling.errorMsgColor}>{email !== emailConfirmation ? ' Email does not match' : ''}</span>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="email"
                                                                    className="form-control placeholderText"
                                                                    id="emailInput"
                                                                    placeholder="Enter Email"
                                                                    value={email}
                                                                    onChange={emailHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    Confirm Email <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="email"
                                                                    className="form-control placeholderText"
                                                                    id="emailConfirmation"
                                                                    placeholder="Confirm Email"
                                                                    value={emailConfirmation}
                                                                    onChange={emailConfirmationHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <div style={customerSignupFormStyling.inputTitleStyle}>
                                                                    Cell Phone <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                </div>
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="text"
                                                                    className="form-control placeholderText"
                                                                    id="cellPhone"
                                                                    placeholder="Enter Cell Phone Number"
                                                                    value={phoneNumber}
                                                                    onChange={phoneNumberHandle}
                                                                ></input>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                {/* blank */}
                                                            </div>
                                                            <div className="col-12">
                                                                <br />
                                                                <br />
                                                                <div>
                                                                    <input
                                                                        type="checkbox"
                                                                        aria-label="Radio button for following text input"
                                                                        value={interestedInLearningMore}
                                                                        id="interestedInLearningMore"
                                                                        onClick={interestedInLearningMoreHandle}
                                                                    >
                                                                    </input>
                                                                    {` I’m not interested to sign up at this time, but want to learn more about Recyclops.`}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <br />
                                                                <div>
                                                                    <input
                                                                        type="checkbox"
                                                                        aria-label="Radio button for following text input"
                                                                        id="interestedInBecomingADriver"
                                                                        value={interestedInBecomingADriver}
                                                                        onClick={interestedInBecomingADriverHandle}
                                                                    >
                                                                    </input>
                                                                    {` I’m interested in becoming a driver for Recyclops.`}
                                                                </div>
                                                            </div>
                                                            <div className="col-12" style={customerSignupFormStyling.inputTitleStyle2}>
                                                                <div style={{ paddingTop: '3' }}></div>
                                                                <br />
                                                                Special Address Instructions - Please enter home/neighborhood gate code, if you live on a corner which street recycling will be on, or any other special instructions if necessary.
                                                            </div>
                                                            <div className="col-12">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <textarea
                                                                    className="form-control placeholderText"
                                                                    aria-label="With textarea"
                                                                    placeholder="(Optional)"
                                                                    id="specialAddressInstructions"
                                                                    value={specialAddressInstructions}
                                                                    onChange={specialAddressInstructionsHandle}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* review starts here */}
                                                <br />
                                                <div className="row">
                                                    <div className="col-2 text-left" style={customerSignupFormStyling.sectionFontTitle}>
                                                        Review
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="row  text-left">
                                                            <div className="col-12" style={customerSignupFormStyling.inputTitleStyle}>
                                                                Your name <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                                <div style={customerSignupFormStyling.inputStyling}
                                                                    className="form-control"
                                                                >
                                                                    <div style={customerSignupFormStyling.errorMsgColor}>{selectedSalesRep}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12" style={customerSignupFormStyling.inputTitleStyle}>
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                Special notes
                                                                <input
                                                                    style={customerSignupFormStyling.inputStyling}
                                                                    type="text"
                                                                    className="form-control placeholderText"
                                                                    id="specialNotes"
                                                                    placeholder=""
                                                                    value={specialNotes}
                                                                    onChange={specialNotesHandle}
                                                                ></input>
                                                            </div>
                                                            {/* <div className="col-12">
                                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                                <input
                                                                    type="radio"
                                                                    aria-label="Radio button for following text input"
                                                                    value={reviewedAndConfirm}
                                                                    onChange={reviewedAndConfirmHandle}
                                                                >
                                                                </input>
                                                                {` I have reviewed and confirm the above information is correct`}
                                                                <span style={customerSignupFormStyling.asteriskColor}>*</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                {/* back & next button */}
                                                <div className='container'>

                                                    <div style={customerSignupFormStyling.errorMsgColor}>{errorMsg ? `Please fill out all required fields` : ''}</div>

                                                    <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                    <div style={customerSignupFormStyling.paddingBetweenInputs}></div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-sm text-center" id='backToggleCustomerSignUpHandle' style={customerSignupFormStyling.buttonForNextPage} onClick={() => toggleCustomerSignUpHandle()}>
                                                                    Back
                                                                </div>
                                                                <div className="col-sm">
                                                                </div>
                                                                <div className="col-sm">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 text-right" id='nextPageHandle' onClick={this.nextPageHandle}>
                                                            <div className="row">
                                                                <div className="col-sm">
                                                                </div>
                                                                <div className="col-sm">
                                                                </div>
                                                                <div className="col-sm text-center" style={customerSignupFormStyling.buttonForNextPage}>
                                                                    {!isLoading ? interestedInLearningMore ? 'Submit' : `Next` : 'Loading...'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Element>
                        </Element>
                    </div>
                </div>
            </form>
        );
    }
}

export default CustomerSignupForm