/* eslint-disable */
import React, { Component } from "react";
import { Auth } from 'aws-amplify';

class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            newPassword: '',
            confirmPassword: '',
            passwordError: false,
            successMsg: false
        };
    }

    newPassWordCodeHandle = (event) => {
        this.setState({
            newPassword: event.target.value
        })
    }

    confirmPassWordCodeHandle = (event) => {
        this.setState({
            confirmPassword: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { newPassword, successMsg, confirmPassword } = this.state
        const { email, code } = this.props

        if (newPassword === confirmPassword) {
            if (code.length > 0, newPassword.length > 0, email.length > 0) {
                Auth.forgotPasswordSubmit(email, code, newPassword)
                    .then(data => {
                        if (data === 'SUCCESS') {
                            this.setState({
                                successMsg: !successMsg
                            })
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                console.logg('error. Input for code, newPassword, or email are incorrect')
            }
        } else {
            console.log('password does not match')
        }
    }

    render() {
        const { successMsg } = this.state
        return (
            <div>
                {!successMsg
                    ? <form onSubmit={this.handleSubmit}>
                        <div>
                            New Password
                        </div>
                        <input type="password" name="password" value={this.state.newPassword} onChange={this.newPassWordCodeHandle} />
                        <div>
                            Confirm Password
                        </div>
                        <input type="password" name="password" value={this.state.confirmPassword} onChange={this.confirmPassWordCodeHandle} />
                        <input type="submit" value="Submit" />
                    </form>
                    : ''
                }
            </div>
        );
    }
}

export default NewPassword