const initialState = {
  county: {
    isCountySelected: false,
    countySelected: '',
    countyDisplayName: '',
    locations: [],
    selectedAreaData: [],
    locationExist: false,
  }
}

const countySelected = (state = initialState, action) => {
  const newState = {
    ...state
  }
  if (action.type === 'COUNTY_SELECTED') {

    newState.county = action.selectedCounty
  }

  return newState

};


export default countySelected
