import { combineReducers } from "redux";
import counter from "../counter";
import currentUser from '../currentUser'
import locationApi from '../locationsApi'
import stateSelected from '../stateSelected'
import countySelected from '../countySelected'
import locationSelected from '../locationSelected'
import locationProfileApi from '../locationProfileApi';

const combinedReducers = combineReducers({
    counter,
    currentUser,
    locationApi,
    stateSelected,
    countySelected,
    locationSelected,
    locationProfileApi
});

export default combinedReducers