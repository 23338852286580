export function selectedAreaServceDataFunc(data) {

    let stateDisplayName = ''
    let serviceState = ''
    let countyDisplayName = ''
    let serviceCounty = ''
    let locationStatus = ''
    let locationName = ''
    let locationDisplayName = ''
    let operationDisplayName = ''
    let operationName = ''

    for (let i = 0; i < data.length; i++) {
        stateDisplayName = data[i].stateDisplayName
        serviceState = data[i].stateName
        countyDisplayName = data[i].countyDisplayName
        serviceCounty = data[i].countyName
        locationStatus = data[i].locationStatus
        locationName = data[i].locationName ? data[i].locationName : ''
        locationDisplayName = data[i].locationDisplayName ? data[i].locationDisplayName : ''
        operationDisplayName = data[i].operationDisplayName
        operationName = data[i].operationName
    }

    return {
        stateDisplayName: stateDisplayName,
        serviceState: serviceState,
        countyDisplayName: countyDisplayName,
        serviceCounty: serviceCounty,
        locationStatus: locationStatus,
        locationName: locationName,
        locationDisplayName: locationDisplayName,
        operationDisplayName: operationDisplayName,
        operationName: operationName
    }
}
