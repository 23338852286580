const billingFormStyling = {
    paddingBetweenInputs: {
        padding: '2%'
    },
    titleFont: {
        color: '#2A9B8D',
        fontWeight: 'bold'
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    asteriskColor: {
        color: 'red'
    },
    formFont: {
        color: '#848484',
        fontSize: '1.3vw'
    },
    inputStyling: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px',
    },
    inputTitleStyle: {
        color: '#848484',
        fontWeight: 'bold',
        fontSize: '1.4vw'
    },
    dropDownStyling: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px',
        width: '40%'
    },
    sectionFontTitle: {
        color: 'black',
        fontSize: '1.3vw'
    },
    buttonForNextPage: {
        background: '#2A9B8D',
        borderRadius: '20px',
        padding: '1.5%',
        color: 'white'
    },
    spacingBetweenDivs: {
        padding: '10%'
    }
};

export default billingFormStyling