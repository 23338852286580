import React, { useState } from "react";
import QRCode from "qrcode.react";

function QRCodeGenerator({ qrGeneratorHandle }) {
    const [qrValue, setQrValue] = useState("");
    const handleOnChange = event => {
        const { value } = event.target;
        setQrValue(value);
    };
    const downloadQRCode = () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${qrValue}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="">
            <br />
            <h1>QR Code and Download</h1>
            <br />
            <div className="center-content">
                <div>Sales Rep's Name</div>
            </div>
            <div className="center-content">
                <input className="qr-input" onChange={handleOnChange} placeholder="Enter sales rep's name" />
            </div>
            <br />
            <div className="center-content">
                {qrValue
                    ? <QRCode
                        id="qr-gen"
                        value={qrValue ? `https://recyclops.com/signup/?salesRepName=${qrValue}` : ''}
                        size={290}
                        level={"H"}
                        includeMargin={true}
                    />
                    : ''}

            </div>
            <p className="center-content">
                {qrValue
                    ? <button className="download-qr-btn" type="button" onClick={downloadQRCode}>
                        Download QR Code
                    </button>
                    : ''}
            </p>
            <p className="center-content">
                <button className="generate-qr-btn" type="button" onClick={qrGeneratorHandle}>
                    Back
                </button>
            </p>
        </div>
    );
}

export default QRCodeGenerator