const locationsDisplayStyle = {
    test: {
        border: '3px solid #2A9B8D',
    },
    cardBoxLayout: {
        border: '1px solid #2A9B8D',
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '100%',
        height: '51px',
        margin: "1%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#2A9B8D'
    },
    selectedCardBoxLayout: {
        border: '1px solid #2A9B8D',
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '100%',
        height: '51px',
        margin: "1%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#2A9B8D',
        color: 'white'
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    serviceContainer: {
        paddingLeft: '4%',
        paddingRight: '4%'
    }
};

export default locationsDisplayStyle