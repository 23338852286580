/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux'
import LocationsDisplay from './locationsData/LocationsDisplay'
import { salesRepGoogleAPI } from '../helpers/api_services/salesRepGoogleSheets'
import axios from 'axios';

class InitialForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            userId: '',
            isLoading: true,
            locationData: [],
            saleReps: []
        };
    }

    async componentDidMount() {
        const { currentUser, locationApi } = this.props

        const rows = await salesRepGoogleAPI();

        let locationData = await locationApi

        if (currentUser) {
            this.setState({
                user: currentUser.userName,
                userId: currentUser.userId,
                isLoading: false,
                saleReps: [...rows]
            })
        }

        if (locationData) {
            for (let key in locationData) {
                this.setState({
                    locationData: [...locationData[key]]
                })
            }
        }
    }

    render() {
        const { user, isLoading, locationData, saleReps } = this.state
        const { currentUser, locationApi, locationProfileApi } = this.props
        return (
            <div className="">
                <div>
                    {
                        isLoading
                            ? 'loading...'
                            :
                            <div>
                                <br />
                                <LocationsDisplay
                                    locationData={locationData}
                                    currentUser={user}
                                    signOut={this.props.signOut}
                                    saleReps={saleReps}
                                    locationProfileApi={locationProfileApi}
                                    locationApi={locationApi}
                                />
                            </div>
                    }
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        locationProfileApi: state.locationProfileApi,
        locationApi: state.locationApi
    };
}

export default connect(mapStateToProps)(InitialForm)